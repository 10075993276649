import {
  setExpandedCostCatalogIds,
  setExpandedEarningCatalogIds,
  setExpandedFinancingCatalogIds,
  setExpandedRiskCatalogIds,
  useExpandedCalculateIds,
  useLoadedProjectId,
  useLoadedProjectVariants,
  useLoadedVariantId,
  useReadOnly,
} from '@client/project/store';
import {
  BankBuildingIcon,
  Button,
  ContextMenuItem,
  DepositIcon,
  FloatingActionButton,
  HintBox,
  InitiateMoneyTransferIcon,
  LoadingIndicator,
  ProtectIcon,
  SlideOver,
  TransactionIcon,
  useComponentDimensions,
  useDocumentTitle,
  useScrollPosition,
} from '@client/shared/toolkit';
import {
  CalculationModelMetadata,
  CalculationModelReadModel,
  useApiGetCalculationModelCostsQuery,
  useApiGetCalculationModelEarningsQuery,
  useApiGetCalculationModelFinancingQuery,
  useApiGetCalculationModelQuery,
  useApiGetCalculationModelRisksQuery,
  useApiGetExchangeDataHasNewImportsQuery,
} from '@client/shared/api';
import classNames from 'classnames';
import cn from 'classnames';
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  CalculateCosts,
  CalculateEarnings,
  CalculateFinancing,
  CalculationModelDeliveryPhaseReadModelExt,
  CostElementSlideOver,
  EarningsElementSlideOver,
  FinancingElementSlideOver,
  ImportForecastSlideOver,
  MIN_TIMELINE_HEADER_HEIGHT,
  RiskElementSlideOver,
  TimeLineBodyGroupProps,
  TimeLineDataContext,
  TimeLineElementsProps,
  TimeLineHeader,
  TimeLineView,
} from '.';
import {
  CostCatalogElement,
  EarningElement,
  FinancingElement,
  RiskElement,
  useCosts,
  useEarnings,
  useFinancing,
  useRisks,
} from '../hooks';
import {
  CostsFilterOption,
  filterDecoratedElementsByLevel,
  flattenDecoratedElements,
  getFlattenedDecoratedElements,
} from '../utils';
import toast from 'react-hot-toast';
import { useValidateProjectPermission } from '@client/shared/permissions';
import TimeLineDataContextProvider from './Timeline/TimeLineDataContext';
import { ArrowDownOnSquareIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { eachMonthOfInterval, endOfMonth, parseISO, startOfMonth } from 'date-fns/fp';
import { format } from 'date-fns';
import { TimeLineBody, TimeLineDragScrollContainer } from './Timeline';
import { OptionalColumn } from './CalculateSectionHeader';
import { CalculateContext } from './CalculateContext';
import { useDispatch } from 'react-redux';
import { settings } from '@client/shared/store';
import { CalculateRisks } from './Risks';
import { ImportCostElementsSlideOver } from './ImportCostElementsSlideOver';

type ActiveSlideOver = 'None' | 'Costs' | 'ImportCosts' | 'Risks' | 'Earnings' | 'Financing' | 'ImportForecast';

export enum Column {
  INFORMATION = 'information',
  ARCHIVED = 'archived',
  CURRENT = 'current',
  OBLIGO = 'obligo',
  OPTIONAL_COLUMN = 'optionalColumn',
}

export interface CalculateProps {
  view: TimeLineView;
  variant?: CalculationModelReadModel;
  archivedVersions?: CalculationModelMetadata[];
  selectedVersionId: string;
  setSelectedVersionId: (VersionId: string) => void;
  optionalColumn: OptionalColumn;
  setOptionalColumn: (column: OptionalColumn) => void;
  obligoColumn: OptionalColumn;
  setObligoColumn: (column: OptionalColumn) => void;
  onCollapse: (level: number) => void;
  onExpand: (catalogIds: string[]) => void;
  expandedCatalogIds?: string[];
  open?: boolean;
  onCollapseContainer: (state: boolean) => void;
  total?: number;
  searchValue?: string;
  barChartData?: boolean;
}

export const CalculateContainer = ({
  showFinance,
  costsFilters = [],
  searchValue = '',
  searchResults = []
}: {
  showFinance: boolean;
  costsFilters: CostsFilterOption[];
  searchValue: string;
  searchResults: string[];
}) => {
  const { t } = useTranslation();
  const { isLoading, setIsLoading, isListCollapsed } = useContext(CalculateContext);

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const loadedProjectVariants = useLoadedProjectVariants();
  const archivedVersions = loadedProjectVariants.data.filter(
    (x) => x.type === 'ArchivedVersion' && x.id !== loadedVariantId
  );

  const [obligoColumn, setObligoColumn] = useState<OptionalColumn>(OptionalColumn.OBLIGO)
  const [optionalColumn, setOptionalColumn] = useState<OptionalColumn>(OptionalColumn.FORECAST);

  const readOnly = useReadOnly();
  const disableCosts = !useValidateProjectPermission(['COSTS_WRITE'], loadedProjectId ?? '') || readOnly;
  const disableEarnings = !useValidateProjectPermission(['EARNINGS_WRITE'], loadedProjectId ?? '') || readOnly;
  const disableRisks = !useValidateProjectPermission(['RISKS_WRITE'], loadedProjectId ?? '') || readOnly;
  const disableFinancing = !useValidateProjectPermission(['FINANCING_WRITE'], loadedProjectId ?? '') || readOnly;

  const [selectedVersionId, setSelectedVersionId] = useState(
    archivedVersions.length > 0 ? archivedVersions[0].id : 'none'
  );
  const { data, isFetching: isLoadingCalculationModel } = useApiGetCalculationModelQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? ''
    },
    { skip: loadedVariantId == null }
  );

  const { data: costData, isFetching: isLoadingCalculationModelCosts } = useApiGetCalculationModelCostsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? ''
    },
    { skip: loadedVariantId == null }
  );

  const { data: earningsData, isFetching: isLoadingCalculationModelEarnings } = useApiGetCalculationModelEarningsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? ''
    },
    { skip: loadedVariantId == null }
  );

  const { data: risksData, isFetching: isLoadingCalculationModelRisks } = useApiGetCalculationModelRisksQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? ''
    },
    { skip: loadedVariantId == null }
  );

  const { data: financeData, isFetching: isLoadingCalculationModelFinance } = useApiGetCalculationModelFinancingQuery(
    {
      projectId: loadedProjectId ?? 'unset',
      calculationModelId: loadedVariantId ?? ''
    },
    { skip: loadedVariantId == null }
  );

  const { data: hasImport, isFetching: isLoadingNewImports } = useApiGetExchangeDataHasNewImportsQuery(
    { proId: loadedProjectId ?? '', calcModelId: loadedVariantId ?? '' },
    { skip: loadedProjectId == null || loadedVariantId == null }
  );

  useEffect(() => {
    if (hasImport === true) {
      toast.success(t('projectCalculate.ForecastImportNote'));
    }
  }, [hasImport, t]);

  useDocumentTitle({ title: t('project.menuCalculate') });

  const [activeSlideOver, setActiveSlideOver] = useState<ActiveSlideOver>('None');
  // const [isOpenNewElementModal, setIsOpenNewElementModal] = useState(false);
  const [showOnlyCosts, setShowOnlyCosts] = useState(false);
  const [currentSearchValue, setCurrentSearchValue] = useState('');
  const [currentSearchResult, setCurrentSearchResult] = useState<string[]|null>(null)

  const dispatch = useDispatch();
  const {
    Cost: expandedCostCatalogIds,
    Risk: expandedRiskCatalogIds,
    Earning: expandedEarningCatalogIds,
    Financing: expandedFinancingCatalogIds,
  } = useExpandedCalculateIds();

  useEffect(() => {
    setShowOnlyCosts(costsFilters.length > 0);
  }, [costsFilters]);

  const [containerOpen, setContainerOpen] = useState<{
    costs: boolean;
    risks: boolean;
    earnings: boolean;
    financing: boolean;
  }>({ costs: true, risks: true, earnings: true, financing: true });

  const contextItems: ContextMenuItem[] = [
    // {
    //   isDisabled: readOnly,
    //   label: t('New element'),
    //   subtitle: t('Add new element'),
    //   icon: <InputIcon src="/assets/icon-plus.svg" />,
    //   onClick: () => setIsOpenNewElementModal(true),
    // },
    {
      isDisabled: disableCosts,
      label: t('projectCalculate.calculateNewCosts'),
      subtitle: t('projectCalculate.calculateAddCostsMenuDescription'),
      icon: <InitiateMoneyTransferIcon />,
      onClick: () => setActiveSlideOver('Costs'),
    },
    {
      isDisabled: disableRisks,
      label: t('projectCalculate.calculateNewRisks'),
      subtitle: t('projectCalculate.calculateAddRiskMenuDescription'),
      icon: <ProtectIcon />,
      onClick: () => setActiveSlideOver('Risks'),
    },
    {
      isDisabled: disableEarnings,
      label: t('projectCalculate.calculateNewEarnings'),
      subtitle: t('projectCalculate.calculateAddEarningsMenuDescription'),
      icon: <DepositIcon />,
      onClick: () => setActiveSlideOver('Earnings'),
    },
    {
      isDisabled: disableFinancing,
      label: t('projectCalculate.calculateNewFinancing'),
      subtitle: t('projectCalculate.calculateAddFinancingMenuDescription'),
      icon: <BankBuildingIcon />,
      onClick: () => setActiveSlideOver('Financing'),
    },
    {
      label: t('projectCalculate.ForecastImport'),
      subtitle: t('projectCalculate.ForecastImportDescription'),
      icon: <TransactionIcon />,
      onClick: () => setActiveSlideOver('ImportForecast'),
    },
    {
      label: t('projectCalculate.costElementImport'),
      subtitle: t('projectCalculate.costElementImportDescription'),
      icon: <ArrowDownOnSquareIcon />,
      onClick: () => setActiveSlideOver('ImportCosts'),
    },
  ];

  const handleCloseSlideOver = () => {
    setActiveSlideOver('None');
  };

  const variant = useMemo(() => {
    return data?.calculationModel;
  }, [data?.calculationModel]);

  // const deliveryPhases = variant?.payload.calculationModelDeliveryPhases ?? [];
  const milestones = useMemo(() => {
    return variant?.payload.milestones ?? [];
  }, [variant?.payload.milestones]);

  useEffect(() => {
    if (searchValue !== currentSearchValue) {
      setCurrentSearchValue(searchValue);
    }
    if (!searchValue) {
      setCurrentSearchResult(null)
    } else {
      setCurrentSearchResult(searchResults)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, searchResults]);
  const costElementsFiltered = useCosts(
    costData?.payload?.catalogElements ?? [],
    costsFilters,
    currentSearchValue,
    currentSearchResult
  );
  const costTimeLineElements: TimeLineElementsProps[] = useMemo(() => {
    const elements = getFlattenedDecoratedElements<CostCatalogElement>(costElementsFiltered);
    const costTimeLineElements: TimeLineElementsProps[] = [];
    elements.forEach(({ element: item, categoryId, parent, level, match }) => {
      costTimeLineElements.push({
        timelineElement: item?.group?.timelineElement,
        level: level,
        id: categoryId,
        parentId: parent?.element.group?.id,
        elementId: item.group?.costElementId,
        type: item.type,
        contracts: item.contracts,
        financeTimeline: item.group?.financeTimeline,
        restBudget: !!(
          (item.group?.modelValues.restBudget &&
            item.group?.modelValues.restBudget !== item.group?.modelValues.effectiveValue)
        ),
        description: item.group?.description,
        elementType: 'costElement',
        matchSearch: currentSearchValue ? match : true,
        hasChildren: item.group?.children ? item.group.children.length > 0 : false,
        commitments: item.commitments ?? [],
        invoicingParties: item.invoicingParties ?? []
      })
      if (item.costElements?.length) {
        item.costElements.forEach((ce, i) => {
          costTimeLineElements.push({
            timelineElement: ce.costElement?.timelineElement,
            level: level + 1,
            parentId: item.group?.id,
            elementId: ce.costElement?.elementId,
            type: 'element',
            contracts: ce.contracts,
            financeTimeline: ce.costElement?.financeTimeline,
            restBudget: !!(
              (ce.costElement?.restBudget && ce.costElement?.restBudget !== ce.costElement?.totalValue)
            ),
            description: ce.costElement?.description,
            elementType: 'costElement',
            matchSearch: currentSearchValue ? match : true,
            hasChildren: false,
            commitments: ce.commitments ?? [],
            invoicingParties: ce.invoicingParties ?? []
          })
        })
      }
    });

    return costTimeLineElements
  }, [costElementsFiltered, currentSearchValue]);

  const shownCostTimeLineElements = useMemo(() => {
    return costTimeLineElements.filter((data) => {
      return (
        (data.level === 0 || expandedCostCatalogIds.includes(data.parentId ?? data.id ?? '') || (!!currentSearchValue && !!data.matchSearch)) &&
        data.type !== 'summary'
      );
    });
  }, [costTimeLineElements, expandedCostCatalogIds, currentSearchValue]);

  const riskElementsFiltered = useRisks(risksData?.payload?.riskElements ?? [], [], currentSearchValue, currentSearchResult);
  const riskTimeLineElements: TimeLineElementsProps[] = useMemo(() => {
    const elements = getFlattenedDecoratedElements<RiskElement>(riskElementsFiltered);
    return elements.map(({ element: item, categoryId, parent, level, match }) => ({
      timelineElement: item?.group ? item?.group?.timelineElement : item?.riskElement?.timelineElement,
      financeTimeline: item.group?.financeTimeline ?? item.riskElement?.financeTimeline,
      level: level,
      id: categoryId,
      parentId: parent?.element.group?.groupId,
      elementId: item.group?.riskElementId ?? item.riskElement?.riskElementId,
      type: item.type,
      description: item.group?.description ?? item.riskElement?.description,
      elementType: 'riskElement',
      matchSearch: currentSearchValue ? match : true,
      hasChildren: item.group?.children ? item.group?.children.length > 0 : false
    }));
  }, [riskElementsFiltered, currentSearchValue]);

  const shownRiskTimeLineElements = useMemo(() => {
    return riskTimeLineElements.filter((data) => {
      return (
        (data.level === 0 || expandedRiskCatalogIds.includes(data.parentId ?? data.id ?? '')) &&
        data.type !== 'summary' &&
        data.matchSearch
      );
    });
  }, [riskTimeLineElements, expandedRiskCatalogIds]);

  const earningElementsFiltered = useEarnings(earningsData?.payload.earnings ?? [], [], currentSearchValue, currentSearchResult);
  const earningTimeLineElements: TimeLineElementsProps[] = useMemo(() => {
    const elements = getFlattenedDecoratedElements<EarningElement>(earningElementsFiltered);
    return elements.map(({ element: item, categoryId, parent, level, match }) => ({
      timelineElement: item?.group ? item?.group?.timelineElement : item?.earningElement?.timelineElement,
      financeTimeline: item.group?.financeTimeline ?? item.earningElement?.financeTimeline,
      level: level,
      id: categoryId,
      parentId: parent?.element.group?.groupId,
      elementId: item.group?.earningsElementId ?? item.earningElement?.id,
      type: item.type,
      description: item.group?.description ?? item.earningElement?.description,
      elementType: 'earningElement',
      matchSearch: currentSearchValue ? match : true,
      hasChildren: item.group?.children ? item.group?.children.length > 0 : false
    }));
  }, [earningElementsFiltered, currentSearchValue]);

  const shownEarningTimeLineElements = useMemo(() => {
    return earningTimeLineElements.filter((data) => {
      return (
        (data.level === 0 || expandedEarningCatalogIds.includes(data.parentId ?? data.id ?? '')) &&
        data.type !== 'summary' &&
        data.matchSearch
      );
    });
  }, [earningTimeLineElements, expandedEarningCatalogIds]);

  const financingElementsFiltered = useFinancing(
    financeData?.payload?.financingGroups ?? [],
    [],
    currentSearchValue
  );
  const financingTimeLineElements = useMemo(() => {
    const elements = getFlattenedDecoratedElements<FinancingElement>(financingElementsFiltered);
    return elements.map(({ element: item, categoryId, level, parent, match }) => ({
      timelineElement: item?.group ? item?.group?.timelineElement : item?.financingElement?.timelineElement,
      financeTimeline: item?.group ? item?.group?.financeTimeline : item?.financingElement?.financeTimeline,
      level: level,
      id: categoryId,
      parentId: parent?.categoryId,
      elementId: item.group?.groupId ?? item.financingElement?.id,
      type: item.type,
      description: item.financingElement?.description,
      elementType: 'financingElement',
      matchSearch: currentSearchValue ? match : true,
    }));
  }, [financingElementsFiltered, currentSearchValue]);

  const shownFinancingTimeLineElements = useMemo(() => {
    return financingTimeLineElements.filter((data) => {
      return (
        (data.level === 0 || expandedFinancingCatalogIds.includes(data.parentId ?? data.id ?? '')) &&
        data.type !== 'summary' &&
        data.matchSearch
      );
    });
  }, [financingTimeLineElements, expandedFinancingCatalogIds]);

  const selectedVersionCostElementsFiltered = useCosts(costData?.payload.catalogElements ?? []);

  const selectedVersionRiskElementsFiltered = useRisks(risksData?.payload?.riskElements ?? []);

  const selectedVersionEarningElementsFiltered = useEarnings(earningsData?.payload.earnings ?? []);

  const selectedVersionFinancingElementsFiltered = useFinancing(financeData?.payload.financingGroups ?? []);

  const collapseCosts = (level: number) => {
    const flatElements = flattenDecoratedElements(costElementsFiltered);
    const ids = filterDecoratedElementsByLevel<CostCatalogElement>(level, flatElements);

    dispatch(setExpandedCostCatalogIds(ids));
  };

  const collapseRisks = (level: number) => {
    const flatElements = flattenDecoratedElements(riskElementsFiltered);
    const ids = filterDecoratedElementsByLevel<RiskElement>(level, flatElements);

    dispatch(setExpandedRiskCatalogIds(ids));
  };

  const collapseEarnings = (level: number) => {
    const flatElements = flattenDecoratedElements(earningElementsFiltered);
    const ids = filterDecoratedElementsByLevel<EarningElement>(level, flatElements);

    dispatch(setExpandedEarningCatalogIds(ids));
  };

  const collapseFinancing = (level: number) => {
    const flatElements = flattenDecoratedElements(financingElementsFiltered);
    const ids = filterDecoratedElementsByLevel<FinancingElement>(level, flatElements);

    dispatch(setExpandedFinancingCatalogIds(ids));
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const scrollPosition = useScrollPosition(containerRef);
  const { width } = useComponentDimensions(containerRef);
  const headerRef = useRef<HTMLDivElement>(null);

  const timeLinePaneRef = useRef<HTMLDivElement>(null);
  const timeLineWrapperRef = useRef<HTMLDivElement>(null);

  const phases: CalculationModelDeliveryPhaseReadModelExt[] = useMemo(() => {
    const currentDeliveryPhases = variant?.payload.calculationModelDeliveryPhases ?? [];
    return currentDeliveryPhases.map((phase) => ({
      id: phase.id,
      code: phase.code,
      name: phase.name,
      durationDays: phase.timeLine.duration ?? 0,
      timeLine: phase.timeLine,
      order: phase.order,
      startDate: phase.timeLine.effectiveStartDate
        ? parseISO(phase.timeLine.effectiveStartDate.substring(0, 10))
        : phase.timeLine.startFixedStartDate
        ? parseISO(phase.timeLine.startFixedStartDate.substring(0, 10))
        : undefined,
      endDate: phase.timeLine.effectiveEndDate
        ? parseISO(phase.timeLine.effectiveEndDate.substring(0, 10))
        : phase.timeLine.endDate
        ? parseISO(phase.timeLine.endDate.substring(0, 10))
        : undefined,
    }));
  }, [variant?.payload.calculationModelDeliveryPhases]);
  const monthsGroupedByYear = useMemo(() => {
    const allYears: Date[][] = [];
    let groupedMonths: Date[] = [];
    const firstPhase = phases[0];
    const lastPhase = phases[phases.length - 1];
    const firstMonth = firstPhase?.startDate ? startOfMonth(firstPhase.startDate) : undefined;
    const lastMonthDate = lastPhase?.endDate;
    const lastMonth = lastMonthDate ? endOfMonth(lastMonthDate) : undefined;
    if (firstMonth && lastMonth) {
      const timelineMonths = eachMonthOfInterval({ start: firstMonth, end: lastMonth });
      timelineMonths.forEach((month) => {
        groupedMonths.push(month);
        const monthNumber = format(month, 'M');
        if (monthNumber === '12') {
          allYears.push(groupedMonths);
          groupedMonths = [];
        }
      });
      if (groupedMonths.length) {
        allYears.push(groupedMonths);
      }
    }
    return allYears;
  }, [phases]);
  const timeLineGroups: TimeLineBodyGroupProps[] = useMemo(() => {
    if (data) {
      if (showOnlyCosts) {
        return [
          {
            elements: shownCostTimeLineElements,
            name: 'costs',
            barChartData: currentSearchValue ? null : costData?.payload.costElementsTotals,
          },
        ];
      }
      return [
        {
          elements: shownCostTimeLineElements,
          name: 'costs',
          barChartData: currentSearchValue ? null : costData?.payload?.costElementsTotals,
        },
        {
          elements: shownRiskTimeLineElements,
          name: 'risks',
          barChartData: currentSearchValue ? null : risksData?.payload?.riskElementsTotals,
        },
        {
          elements: shownEarningTimeLineElements,
          name: 'earnings',
          barChartData: currentSearchValue ? null : earningsData?.payload?.earningElementsTotals,
        },
        {
          elements: shownFinancingTimeLineElements,
          name: 'financing',
        },
      ];
    }
    return [];
  }, [
    data,
    costData,
    risksData,
    earningsData,
    shownCostTimeLineElements,
    shownRiskTimeLineElements,
    shownEarningTimeLineElements,
    shownFinancingTimeLineElements,
    showOnlyCosts,
    currentSearchValue
  ]); // only when data updates, we need to refresh

  const dueDate = useMemo(() => {
    return data?.calculationModel?.modelMetadata?.cashFlowSettings?.statusMonth;
  }, [data?.calculationModel?.modelMetadata?.cashFlowSettings?.statusMonth]);

  const barChartDataCosts = useMemo(() => {
    return timeLineGroups && timeLineGroups[0] && timeLineGroups[0].barChartData;
  }, [timeLineGroups]);

  const barChartDataRisks = useMemo(() => {
    return timeLineGroups && timeLineGroups[1] && timeLineGroups[1].barChartData;
  }, [timeLineGroups]);

  const barChartDataEarnings = useMemo(() => {
    return timeLineGroups && timeLineGroups[2] && timeLineGroups[2].barChartData;
  }, [timeLineGroups]);

  // Prevent to load too many years and crashing the view (tmp solution, because this happens if some dates are imported wrongly).
  if (monthsGroupedByYear.length >= 100) {
    return (
      <HintBox hintType="danger">
        ERROR - Exceeding limit: {monthsGroupedByYear.length} years
      </HintBox>
    );
  }

  return (
    <div ref={containerRef} className="w-full h-full overflow-y-auto">
      {(isLoading || loadedVariantId == null || isLoadingNewImports || isLoadingCalculationModelEarnings || isLoadingCalculationModelRisks || isLoadingCalculationModelCosts || isLoadingCalculationModel || isLoadingCalculationModelFinance) && <LoadingIndicator mode={'overlay-window'} />}
      {loadedVariantId == null ? (
        <div>Temporarily empty, TODO bschuedzig</div>
      ) : (
        <>
          <TimeLineDataContextProvider
            dragScrollContainerRef={timeLineWrapperRef}
            timeLineHeaderRef={headerRef}
            loadedProjectId={loadedProjectId}
            containerRef={containerRef}
            phases={phases}
            milestones={milestones}
            timeLineView={showFinance ? TimeLineView.FINANCE : TimeLineView.TIMELINE}
          >
            <CalculationContainerStickyHeaderGroupTitle width={width} scrollPositionY={scrollPosition.y} />
            <div className="flex pt-8 pb-8 pl-8 pr-8 xl:pr-0">
              <div
                className={classNames('w-full', {
                  // 'xl:w-2/3 2xl:w-3/6': showFinance,
                  // 'xl:w-3/6': !showFinance,
                  'xl:max-w-[400px]': isListCollapsed,
                  'xl:max-w-[900px]': !isListCollapsed
                })}
              >
                <CalculateCosts
                  view={showFinance ? TimeLineView.FINANCE : TimeLineView.TIMELINE}
                  elements={costElementsFiltered}
                  onCollapse={(level) => collapseCosts(level)}
                  expandedCatalogIds={expandedCostCatalogIds}
                  onExpand={(ids) => dispatch(setExpandedCostCatalogIds(ids))}
                  variant={variant}
                  archivedVersions={archivedVersions}
                  selectedVersionId={selectedVersionId}
                  setSelectedVersionId={setSelectedVersionId}
                  selectedVersionElements={selectedVersionCostElementsFiltered}
                  optionalColumn={optionalColumn}
                  setOptionalColumn={setOptionalColumn}
                  obligoColumn={obligoColumn}
                  setObligoColumn={setObligoColumn}
                  open={containerOpen.costs}
                  onCollapseContainer={(value) => setContainerOpen({ ...containerOpen, costs: value })}
                  total={isLoading ? 0 : shownCostTimeLineElements.length} // fixes not ending loading indicator when clicking on level on page load
                  setIsLoading={setIsLoading}
                  searchValue={currentSearchValue}
                  barChartData={!!barChartDataCosts}
                />
                {!showOnlyCosts && (
                  <>
                    <CalculateRisks
                      view={showFinance ? TimeLineView.FINANCE : TimeLineView.TIMELINE}
                      elements={riskElementsFiltered}
                      onCollapse={(level) => collapseRisks(level)}
                      expandedCatalogIds={expandedRiskCatalogIds}
                      onExpand={(ids) => dispatch(setExpandedRiskCatalogIds(ids))}
                      variant={variant}
                      archivedVersions={archivedVersions}
                      selectedVersionId={selectedVersionId}
                      setSelectedVersionId={setSelectedVersionId}
                      selectedVersionElements={selectedVersionRiskElementsFiltered}
                      optionalColumn={optionalColumn}
                      setOptionalColumn={setOptionalColumn}
                      obligoColumn={obligoColumn}
                      setObligoColumn={setObligoColumn}
                      open={containerOpen.risks}
                      onCollapseContainer={(value) => setContainerOpen({ ...containerOpen, risks: value })}
                      barChartData={!!barChartDataRisks}
                      searchValue={searchValue}
                    />
                    <CalculateEarnings
                      view={showFinance ? TimeLineView.FINANCE : TimeLineView.TIMELINE}
                      elements={earningElementsFiltered}
                      onCollapse={(level) => collapseEarnings(level)}
                      expandedCatalogIds={expandedEarningCatalogIds}
                      onExpand={(ids) => dispatch(setExpandedEarningCatalogIds(ids))}
                      variant={variant}
                      archivedVersions={archivedVersions}
                      selectedVersionId={selectedVersionId}
                      setSelectedVersionId={setSelectedVersionId}
                      selectedVersionElements={selectedVersionEarningElementsFiltered}
                      optionalColumn={optionalColumn}
                      setOptionalColumn={setOptionalColumn}
                      obligoColumn={obligoColumn}
                      setObligoColumn={setObligoColumn}
                      open={containerOpen.earnings}
                      onCollapseContainer={(value) => setContainerOpen({ ...containerOpen, earnings: value })}
                      barChartData={!!barChartDataEarnings}
                      searchValue={searchValue}
                    />
                    <CalculateFinancing
                      view={showFinance ? TimeLineView.FINANCE : TimeLineView.TIMELINE}
                      elements={financingElementsFiltered}
                      onCollapse={(level) => collapseFinancing(level)}
                      expandedCatalogIds={expandedFinancingCatalogIds}
                      onExpand={(ids) => dispatch(setExpandedFinancingCatalogIds(ids))}
                      variant={variant}
                      archivedVersions={archivedVersions}
                      selectedVersionId={selectedVersionId}
                      setSelectedVersionId={setSelectedVersionId}
                      selectedVersionElements={selectedVersionFinancingElementsFiltered}
                      optionalColumn={optionalColumn}
                      setOptionalColumn={setOptionalColumn}
                      obligoColumn={obligoColumn}
                      setObligoColumn={setObligoColumn}
                      open={containerOpen.financing}
                      onCollapseContainer={(value) => setContainerOpen({ ...containerOpen, financing: value })}
                      searchValue={searchValue}
                    />
                  </>
                )}
              </div>

              {/* ------------- TIMELINE START ----------- */}
              <div
                className={classNames('hidden xl:block  ml-2 overflow-clip flex-1', {
                  'xl:w-1/3 2xl:w-3/6': showFinance,
                  'xl:w-3/6': !showFinance,
                })}
                ref={timeLineWrapperRef}
              >
                <CalculationContainerStickyHeaderTimeline
                  headerRef={headerRef}
                  phases={phases}
                  scrollPositionY={scrollPosition.y}
                >
                  <TimeLineHeader
                    phases={phases}
                    milestones={milestones}
                    view={showFinance ? TimeLineView.FINANCE : TimeLineView.TIMELINE}
                    monthsGroupedByYear={monthsGroupedByYear}
                    timeLineHeaderRef={headerRef}
                    dueDate={dueDate}
                  />
                </CalculationContainerStickyHeaderTimeline>
                <TimeLineDragScrollContainer
                  containerRef={timeLinePaneRef}
                  syncRefs={[headerRef]}
                  showFinance={showFinance}
                  updateIsDragging={(isDragging) => {
                    // updating dragging in use state was not performant, this is a workaround...
                    if (timeLineWrapperRef.current) {
                      if (isDragging) {
                        timeLineWrapperRef.current.setAttribute('dragging', 'true');
                      } else {
                        timeLineWrapperRef.current.removeAttribute('dragging');
                      }
                    }
                  }}
                >
                  <TimeLineBody
                    view={showFinance ? TimeLineView.FINANCE : TimeLineView.TIMELINE}
                    milestones={milestones}
                    phases={phases}
                    monthsGroupedByYear={monthsGroupedByYear}
                    groups={timeLineGroups}
                    containerOpen={containerOpen}
                    expandedIds={[
                      expandedCostCatalogIds,
                      expandedRiskCatalogIds,
                      expandedEarningCatalogIds,
                      expandedFinancingCatalogIds,
                    ]}
                    dueDate={dueDate}
                  />
                </TimeLineDragScrollContainer>
              </div>
            </div>
          </TimeLineDataContextProvider>
          {!readOnly && (
            <FloatingActionButton menuItems={contextItems}/>
          )}
          <SlideOver isOpen={activeSlideOver === 'Costs'} onClose={handleCloseSlideOver}>
            <CostElementSlideOver
              disabled={disableCosts}
              variantId={loadedVariantId}
              catalogId={variant?.payload?.costCatalogId}
              onClose={handleCloseSlideOver}
              timelineErrors={[]}
              contractBudgetErrors={[]}
            />
          </SlideOver>
          <SlideOver isOpen={activeSlideOver === 'Risks'} onClose={handleCloseSlideOver}>
            <RiskElementSlideOver
              disabled={disableRisks}
              onClose={handleCloseSlideOver}
              riskCatalogId={variant?.payload.riskCatalogId ?? undefined}
              variantId={loadedVariantId}
            />
          </SlideOver>
          <SlideOver isOpen={activeSlideOver === 'Earnings'} onClose={handleCloseSlideOver}>
            <EarningsElementSlideOver
              disabled={disableEarnings}
              onClose={handleCloseSlideOver}
              variantId={loadedVariantId}
              earningsCatalogId={variant?.payload.earningsCatalogId ?? undefined}
            />
          </SlideOver>
          <SlideOver isOpen={activeSlideOver === 'Financing'} onClose={handleCloseSlideOver}>
            <FinancingElementSlideOver
              disabled={disableFinancing}
              onClose={handleCloseSlideOver}
              variantId={loadedVariantId}
              catalogId={variant?.payload?.costCatalogId ?? undefined}
              financingCatalogId={variant?.payload?.financingCatalogId ?? undefined}
              errors={[]}
            />
          </SlideOver>
          <SlideOver isOpen={activeSlideOver === 'ImportForecast'} onClose={handleCloseSlideOver}>
            <ImportForecastSlideOver
              onClose={handleCloseSlideOver}
              calcModelId={loadedVariantId}
              projectId={loadedProjectId ?? ''}
            />
          </SlideOver>
          <SlideOver variant='large' isOpen={activeSlideOver === 'ImportCosts'} onClose={handleCloseSlideOver}>
            <ImportCostElementsSlideOver
              onClose={handleCloseSlideOver}
            />
          </SlideOver>
        </>
      )}
    </div>
  );
};

interface CalculationContainerStickyHeaderGroupTitleProps {
  scrollPositionY?: number
  width?: number
}

const CalculationContainerStickyHeaderGroupTitle = ({
  scrollPositionY = 0,
  width
}: CalculationContainerStickyHeaderGroupTitleProps) => {
  const stickyHeaderRef = useRef<HTMLDivElement>(null);
  const { headerHeight } = useContext(TimeLineDataContext);
  const initialScrollPosition = scrollPositionY <= 32

  return (
    <div
      className={classNames('w-full bg-slate-50 fixed', {
        'opacity-0 pointer-events-none': initialScrollPosition,
        'opacity-100 h-16 bg-white shadow-lg z-[9] pointer-events-auto': !initialScrollPosition
      })}
      style={{
        width: width,
        height: headerHeight ? headerHeight : undefined,
      }}
      ref={stickyHeaderRef}
    />
  );
};

interface CalculationContainerStickyHeaderTimelineProps extends PropsWithChildren {
  headerRef: React.RefObject<HTMLDivElement>;
  phases?: CalculationModelDeliveryPhaseReadModelExt[];
  scrollPositionY?: number
}

const CalculationContainerStickyHeaderTimeline = ({
  children,
  headerRef,
  phases = [],
  scrollPositionY = 0
}: CalculationContainerStickyHeaderTimelineProps) => {
  const {
    isHeaderCollapsed,
    headerHeight,
    setIsHeaderCollapsed,
    setHeaderHeight,
  } = useContext(TimeLineDataContext);

  const [headerInitialHeight, setHeaderInitialHeight] = useState(MIN_TIMELINE_HEADER_HEIGHT)
  const isDevMode = settings.devMode;
  const initialScrollPosition = scrollPositionY <= 32;

  useEffect(() => {
    if (scrollPositionY && initialScrollPosition) {
      setIsHeaderCollapsed(false);
      setHeaderHeight(headerInitialHeight);
    }
    if (!initialScrollPosition) {
      setIsHeaderCollapsed(true);
      setHeaderHeight(MIN_TIMELINE_HEADER_HEIGHT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialScrollPosition]);

  useEffect(() => {
    if (!headerRef.current || headerHeight) return;
    const resizeObserver = new ResizeObserver(() => {
      if (headerRef.current && headerRef.current.clientHeight) {
        setHeaderHeight(headerRef.current.clientHeight);
        setHeaderInitialHeight(headerRef.current.clientHeight);
      }
    });
    resizeObserver.observe(headerRef.current);
    return () => resizeObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef.current, headerHeight, setHeaderHeight, setHeaderInitialHeight]);
  const toggleHeader = useCallback(() => {
    setHeaderHeight(isHeaderCollapsed ? headerInitialHeight : MIN_TIMELINE_HEADER_HEIGHT);
    setIsHeaderCollapsed(!isHeaderCollapsed);
  }, [setHeaderHeight, isHeaderCollapsed, headerInitialHeight, setIsHeaderCollapsed]);
  const showCollapseButton = useMemo(() => {
    return isHeaderCollapsed || !initialScrollPosition;
  }, [isHeaderCollapsed, initialScrollPosition]);
  useEffect(() => {
    // if phases change, we reset the height (which triggers resize observer and sets the height again correctly)
    setHeaderInitialHeight(MIN_TIMELINE_HEADER_HEIGHT);
    setHeaderHeight(0);
  }, [phases, setHeaderHeight, setHeaderInitialHeight]);
  return (
    <div
      className={classNames(
        'cursor-pointer sticky top-0 z-10 overflow-hidden transition-all duration-300 flex flex-col justify-end',
        {
          'h-16 bg-white z-[9]': !initialScrollPosition,
          'bg-transparent': initialScrollPosition,
        }
      )}
      style={{
        height: headerHeight ? headerHeight : undefined,
      }}
    >
      <div className="flex items-center h-auto relative will-change-transform" ref={headerRef}>
        {children}
      </div>
      {showCollapseButton && (
        <Button
          variant="primary"
          className={cn('aspect-square shadow-lg fixed right-5 z-50 flex justify-center items-center bottom-auto w-8 h-8', isDevMode ? 'top-36' : 'top-28' )}
          onClick={toggleHeader}
          hasPadding={false}
        >
          <ChevronDownIcon
            className={cn('w-4 h-4 transition-transform duration-300', {
              '-rotate-180': !isHeaderCollapsed,
            })}
          />
        </Button>
      )}
    </div>
  );
};
