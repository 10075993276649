import {
  CurrencyExchangeIcon,
  BaseSelect,
  Button,
  ContractNumberIcon,
  EstimateIcon,
  CreateIcon,
  PaidSearchIcon,
  Form,
  FormField,
  FormRefHandle,
  FormWatch,
  LoadingIndicator,
  Modal,
  NumberInput,
  PercentageIcon,
  PriceTagIcon,
  SlideOver,
  SlideOverOnCloseProps,
  TextInput,
  RenameIcon,
  SlideOverTitle,
  BooleanInput
} from '@client/shared/toolkit';
import {
  CalculateContractTitlePayload,
  ContractReadModel,
  ContractTitleReadModel,
  ContractTitleState,
  useApiPostCalculateContractTitleMutation,
  useApiPostUpdateContractTitleMutation,
  CreateBudgetAssignmentPayload,
  UpdateBudgetAssignmentPayload,
  ContractTitleType, useApiPostCreateContractTitleMutation,
  UserDefinedFieldPayload,
  // BudgetLevel,
} from '@client/shared/api';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { safeMutation } from '@client/shared/utilities';
import {
  ContractTitleEditFormValidationSchema,
  ContractTitleEditFormValidationValues,
} from './ContractTitleEditFormValidationValues';
import { useLoadedProject, useLoadedVariantId } from '@client/project/store';
import { ContractTitleDeleteModal } from './ContractTitleDeleteModal';
import { useValidateProjectPermission } from '@client/shared/permissions';
import {
  BudgetAssignment,
  BudgetLevelModal,
} from '../Budgeting';
import {
  ProjectTaxPickerInput
} from '../ProjectTaxPickerInput'
import classNames from 'classnames';
import { ContractTitleDocuments } from './Documents';
import cn from 'classnames';
import { AuditLog, EditUserDefinedFields } from '..';
import { getContractTitleTypeLabel, getContractTitleStateLabel } from '../../utils';

type SubArea = 'Values' | 'Documents' | 'History';

interface ContractEditTitleSlideOverProps extends SlideOverOnCloseProps {
  contract: ContractReadModel;
  title?: ContractTitleReadModel;
  type?: ContractTitleType
  state?: ContractTitleState
}

export const ContractEditTitleSlideOver = ({ onClose, contract, title, type, state }: ContractEditTitleSlideOverProps) => {
  const { t } = useTranslation();

  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<FormRefHandle<ContractTitleEditFormValidationValues>>();

  const [update, { isLoading: isUpdating }] = useApiPostUpdateContractTitleMutation();
  const [getCalculation, { isLoading: isGettingCalculation }] = useApiPostCalculateContractTitleMutation();
  const [create, { isLoading: isCreating }] = useApiPostCreateContractTitleMutation()

  const [isLoadingUserDefinedFields, setIsLoadingUserDefinedFields] = useState<boolean>(false);

  const [getCalculationValues, setGetCalculationValues] = useState<CalculateContractTitlePayload | null>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState<ContractTitleState | null>(null);
  const [subarea, setSubarea] = useState<SubArea>('Values');

  const loadedProject = useLoadedProject().currentData?.project.payload;

  const loadedProjectId = loadedProject?.id;
  const loadedVariantId = useLoadedVariantId();

  const defaultVat = contract.vat ?? loadedProject?.vat ?? 0;
  const defaultDiscount = contract.discount ?? 0;

  const canWrite = useValidateProjectPermission(['CONTRACT_WRITE'], loadedProjectId ?? '') && !contract.isPxContract;
  const canDelete = useValidateProjectPermission(['CONTRACT_DELETE'], loadedProjectId ?? '') && !contract.isPxContract;

  const [addedBudgetAssignments, setAddedBudgetAssignments] = useState<CreateBudgetAssignmentPayload[]>([]);
  const [updatedBudgetAssignments, setUpdatedBudgetAssignments] = useState<UpdateBudgetAssignmentPayload[]>([]);
  const [deletedBudgetAssignments, setDeletedBudgetAssignments] = useState<string[]>([]);
  const [validBudget, setValidBudget] = useState<boolean>(true);
  const [isBudgetAssignmentPopoverOpen, setIsBudgetAssignmentPopoverOpen] = useState<boolean>(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [customFieldsAreValid, setCustomFieldsAreValid] = useState(true)
  const [udfUpdatePayload, setUdfUpdatePayload] = useState<UserDefinedFieldPayload[] | undefined>();

  const [oldForecastValue, setOldForecastValue] = useState(title?.forecastValueNet ?? 0)

  // const [sourceBudgetLevel, setSourceBudgetLevel] = useState<BudgetLevel | null>();
  const [showBudgetLevelModal, setShowBudgetLevelModal] = useState<boolean>(false);

  const handleSubmit = async (data: ContractTitleEditFormValidationValues) => {
    if (contract?.id && loadedProjectId && loadedVariantId && validBudget && customFieldsAreValid) {
      if (data.id) {
        try {
          await safeMutation(
            update,
            {
              contractId: contract.id,
              contractTitleId: data.id,
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              body: {
                code: data.code,
                name: data.name,
                description: data.description ?? null,
                type: data.type,
                allowEarlySettlement: data.type !== 'Reserve' ? data.allowEarlySettlement : false,
                state: data.state,
                vat: data.vat,
                discount: data.discount,
                offer: data.offer,
                approvedOffer: data.approvedOffer,
                adjustmentValue: data.adjustmentValue,
                addedBudgetAssignments: addedBudgetAssignments,
                updatedBudgetAssignments: updatedBudgetAssignments,
                deletedBudgetAssignments: deletedBudgetAssignments,
                userDefinedFields: udfUpdatePayload,
              },
            },
            isUpdating,
          );
          onClose(true);
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          await safeMutation(
            create,
            {
              contractId: contract.id,
              projectId: loadedProjectId,
              calculationModelId: loadedVariantId,
              body:
                {
                  code: data.code,
                  name: data.name,
                  description: data.description ?? null,
                  type: data.type,
                  allowEarlySettlement: data.type !== 'Reserve' ? data.allowEarlySettlement : false,
                  state: data.state,
                  vat: data.vat,
                  discount: data.discount,
                  offer: data.offer,
                  approvedOffer: data.approvedOffer,
                  adjustmentValue: data.adjustmentValue,
                  budgetAssignments: [],
                  userDefinedFields: udfUpdatePayload,
                }
            },
            isCreating
          )
          onClose(true);
        } catch (e) {
          console.error(e);
        }
      }
    }
  };

  const defaultFormValues = {
    id: title?.id ?? '',
    contractId: contract.id,
    code: title?.code ?? '',
    name: title?.name ?? '',
    description: title?.description ?? '',
    type: title?.type ?? type ?? 'MainContract',
    allowEarlySettlement: title?.allowsEarlySettlement ?? false,
    state: title?.state ?? state ?? 'Expected',
    vat: title?.vat ?? defaultVat ?? 0,
    discount: title?.discount ?? defaultDiscount ?? 0,
    offer: title?.offerNet ?? 0,
    approvedOffer: title?.approvedOfferNet ?? 0,
    contractValue: title?.contractValueNet ?? 0,
    adjustmentValue: title?.adjustmentValueNet ?? 0,
    forecastValue: title?.forecastValueNet ?? 0,
    budget: title?.budgetValueNet ?? 0,
  };

  const titleType = title?.type ?? type

  const contractTitleTypeOptions = useMemo(() => {
    if (titleType === 'Reserve' || !contract.titles.some(x => x.type === 'Reserve')){
      return [
        {
          label: getContractTitleTypeLabel('MainContract'),
          value: 'MainContract',
        },
        {
          label: getContractTitleTypeLabel('Supplement'),
          value: 'Supplement',
        },
        {
          label: getContractTitleTypeLabel('Reserve'),
          value: 'Reserve',
        }
      ];
    } else {
      return [
        {
          label: getContractTitleTypeLabel('MainContract'),
          value: 'MainContract',
        },
        {
          label: getContractTitleTypeLabel('Supplement'),
          value: 'Supplement',
        }
      ];
    }
  }, [titleType, contract]);

  const getContractTitleStateOptions = (type : ContractTitleType | undefined) => {
    switch (type){
      case 'MainContract':
        return [
          {
            label: getContractTitleStateLabel('Expected'),
            value: 'Expected',
          },
          {
            label: getContractTitleStateLabel('Optional'),
            value: 'Optional',
          },
          {
            label: getContractTitleStateLabel('Commissioned'),
            value: 'Commissioned',
          },
        ];
      case 'Supplement':
        return [
          {
            label: getContractTitleStateLabel('Expected'),
            value: 'Expected',
          },
          {
            label: getContractTitleStateLabel('Announced'),
            value: 'Announced',
          },
          {
            label: getContractTitleStateLabel('Budgeted'),
            value: 'Budgeted',
          },
          {
            label: getContractTitleStateLabel('Received'),
            value: 'Received',
          },
          {
            label: getContractTitleStateLabel('Approved'),
            value: 'Approved',
          },
          {
            label: getContractTitleStateLabel('Commissioned'),
            value: 'Commissioned',
          },
          {
            label: getContractTitleStateLabel('Rejected'),
            value: 'Rejected',
          },
          {
            label: getContractTitleStateLabel('Canceled'),
            value: 'Canceled',
          },
        ];
      case 'Reserve':
        return [
          {
            label: getContractTitleStateLabel('Reserve'),
            value: 'Reserve',
          }]
      default:
        return [];
    }
  };

  const handleDelete = () => {
    setIsDeleteModalOpen(true);
  };

  const handleCalculateValuesChanged = async (values?: CalculateContractTitlePayload) => {
    const payload = values ? values : getCalculationValues;
    if (loadedProjectId && loadedVariantId && !isGettingCalculation && payload) {
      const calculationResponse = await safeMutation(
        getCalculation,
        {
          projectId: loadedProjectId,
          calculationModelId: loadedVariantId,
          body: payload,
        },
        isGettingCalculation,
      );
      if (typeof calculationResponse !== 'undefined') {
        formRef.current?.setValue('forecastValue', calculationResponse.forecastValueNet);
        formRef.current?.setValue('contractValue', calculationResponse.contractValueNet);
        setOldForecastValue(calculationResponse.forecastValueNet);
      }
    }
    return;
  };

  const handleForecastValueChanged = useCallback(() => {
    const values = formRef.current?.getValues();

    const adjustmentValue = values?.adjustmentValue ?? 0;
    const forecastValue = values?.forecastValue ?? 0;
    // const contractValue = values?.contractValue ?? 0;
    // const delta = foreacstValue - contractValue - adjustmentValue;

    if (forecastValue !== oldForecastValue) {
      const newAdjustmentValue = adjustmentValue + (forecastValue - oldForecastValue);
      formRef.current?.setValue('adjustmentValue', newAdjustmentValue);
      setOldForecastValue(forecastValue)
    }
  }, [oldForecastValue])

  useEffect(() => {
    if (selectedState && getCalculationValues) {
      handleCalculateValuesChanged(getCalculationValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  const handleShowBudgetLevelModal = () => {
    // const sourceLevel: BudgetLevel = 'Title'
    // setSourceBudgetLevel(sourceLevel);
    setShowBudgetLevelModal(true);
  }

  return (
    <>
      <SlideOver.Header
        title={title?.name ?? t('projectContract.createNewTitle')}
        subTitle={title ? t('projectContract.editContractTitle') : t('projectContract.createNewTitleDescription')}
        onClose={onClose}
        backgroundClassName="bg-gray-900"
      >
        <div className="flex flex-row pt-2 pl-8 bg-gray-900 text-white">
          <button
            type="button"
            className={classNames('px-4 pb-1 text block border-l border-r', {
              'font-bold border-b-2 border-white': subarea === 'Values',
            })}
            onClick={() => setSubarea('Values')}
          >
            <div className="flex felx-row items-center">{t('common.values')}</div>
          </button>
          {title && (
            <>
              <button
                type="button"
                className={classNames('px-4 pb-1 text block border-r', {
                  'font-bold border-b-2 border-white': subarea === 'Documents',
                })}
                onClick={() => setSubarea('Documents')}
              >
                <div className="flex felx-row items-center">{t('common.documents')}</div>
              </button>
              <button
                type="button"
                className={classNames('px-4 pb-1 text block border-r', {
                  'font-bold border-b-2 border-white': subarea === 'History',
                })}
                onClick={() => setSubarea('History')}
              >
                <div className="flex felx-row items-center">{t('common.history')}</div>
              </button>
            </>
          )}
        </div>
      </SlideOver.Header>

      <Form<ContractTitleEditFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={ContractTitleEditFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col flex-grow min-h-0"
        ref={formRef}
      >
        <SlideOver.Content
          onKeyEnter={() => {
            if (!isBudgetAssignmentPopoverOpen) {
              submitRef.current?.click();
            }
          }}
        >
          {(isUpdating || isCreating || isLoadingUserDefinedFields) && (
            <LoadingIndicator text={t('projectContract.saveContractTitleLoadingIndicator')} mode="overlay-window" />
          )}
          {subarea === 'History' && title && (
            <div className="m-8">
              <AuditLog
                id={title.logicalId}
                targetType='ContractTitle'
              />
            </div>
          )}
          {subarea === 'Documents' && title && (
            <div className="m-8">
              <ContractTitleDocuments
                title={title}
                canWrite={canWrite}
                canDelete={canDelete}
              />
            </div>
          )}
          {subarea === 'Values' && (
            <div>
              <div className="m-8 bg-white">
                <div className="divide-gray-100 divide-y">
                  <FormField name="type">
                    {(control) => (
                      <BaseSelect
                        options={contractTitleTypeOptions}
                        label={t('projectContract.contractTitleType')}
                        icon={<PriceTagIcon className="h-6 w-6" />}
                        disabled={!canWrite}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="code">
                    {(control) => (
                      <TextInput
                        label={t('projectContract.contractTitleCode')}
                        icon={<ContractNumberIcon className="h-6 w-6" />}
                        disabled={!canWrite}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="name">
                    {(control) => (
                      <TextInput
                        label={t('projectContract.contractTitleName')}
                        icon={<RenameIcon className="h-6 w-6" />}
                        disabled={!canWrite}
                        {...control}
                      />
                    )}
                  </FormField>
                  <FormField name="description">
                    {(control) => (
                      <TextInput
                        label={t('projectContract.contractDescription')}
                        inputType="textarea"
                        className="font-normal"
                        icon={<CreateIcon className="h-6 w-6" />}
                        disabled={!canWrite}
                        {...control}
                      />
                    )}
                  </FormField>
                </div>
              </div>
              <SlideOverTitle title={t('projectContract.contractStatus')} className="px-8" />
              <div className="mx-8 mb-8 bg-white">
                <div className="divide-gray-100 divide-y">
                  <FormWatch<ContractTitleEditFormValidationValues>
                    onChange={({ state, type }) => {
                      const availableStates = getContractTitleStateOptions(type);

                      if (availableStates.length > 0 && !availableStates.some(x => x.value === state?.toString())){
                        formRef.current?.setValue('state', availableStates[0].value);
                      }
                    }}
                    fieldNames={['type', 'state']}>
                    {({ type }) => (
                      <>
                        {type !== 'Reserve' &&
                          <FormField name="allowEarlySettlement">
                            {(control) => (
                              <BooleanInput
                                variant='checkbox-left'
                                label={t('projectContract.allowEarlySettlement')}
                                disabled={!canWrite}
                                value={control.value}
                                onChange={control.onChange}
                              />
                            )}
                          </FormField>
                        }
                        <FormField name="state">
                          {(control) => (
                            <BaseSelect
                              label={t('projectContract.contractStatus')}
                              icon={<PaidSearchIcon className="h-6 w-6" />}
                              options={getContractTitleStateOptions(type)}
                              disabled={!canWrite}
                              {...control}
                            />
                          )}
                        </FormField>
                      </>
                    )}
                  </FormWatch>
                </div>
              </div>
              <SlideOverTitle title={t('projectContract.contractValue')} className="px-8" />
              <FormWatch<ContractTitleEditFormValidationValues>
                onChange={({ state, budget, discount, vat, offer, approvedOffer, adjustmentValue }) => {
                  const newValues = {
                    state: state as ContractTitleState,
                    discount: discount ?? 0,
                    vat: vat ?? 0,
                    budgetNet: budget ?? 0,
                    approvedOfferNet: approvedOffer ?? 0,
                    adjustmentValueNet: adjustmentValue ?? 0,
                  };

                  if (state !== selectedState && state) {
                    setSelectedState(state);
                    if (state !== 'Optional' && state !== 'Received' &&
                      state !== 'Commissioned' && state !== 'Approved') {
                      formRef.current?.setValue('offer', 0);
                      formRef.current?.setValue('approvedOffer', 0);
                      newValues.approvedOfferNet = 0;
                    }

                    if (state === 'Canceled' || state === 'Budgeted') {
                      formRef.current?.setValue('adjustmentValue', 0);
                      newValues.adjustmentValueNet = 0;
                    }

                    setGetCalculationValues(newValues);
                  } else if (
                    !getCalculationValues ||
                    (getCalculationValues &&
                      (newValues.discount !== getCalculationValues.discount ||
                        newValues.state !== getCalculationValues.state ||
                        newValues.budgetNet !== getCalculationValues.budgetNet ||
                        newValues.approvedOfferNet !== getCalculationValues.approvedOfferNet ||
                        newValues.adjustmentValueNet !== getCalculationValues.adjustmentValueNet))
                  ) {
                    setGetCalculationValues(newValues);
                  }
                }}
                fieldNames={['state', 'budget', 'discount', 'vat', 'offer', 'approvedOffer', 'adjustmentValue']}
              >
                {({ state }) => (
                  <div className="m-8 mt-0">
                    <div className="flex flex-col gap-0.5 pb-3">
                      <FormField name="vat">
                        {(control) => (
                          <ProjectTaxPickerInput
                            disabled={!canWrite}
                            {...control}
                          />
                        )}
                      </FormField>
                      <div className="flex gap-0.5">
                        <div className="w-1/2 flex-none">
                          <FormField name="offer">
                            {(control) => (
                              <NumberInput
                                label={t('projectContract.offer')}
                                icon={<EstimateIcon className="h-6 w-6" />}
                                disabled={
                                  (state !== 'Optional' && state !== 'Received' && state !== 'Commissioned'
                                  && state !== 'Approved') || !canWrite
                                }
                                onBlur={handleCalculateValuesChanged}
                                {...control}
                              />
                            )}
                          </FormField>
                        </div>
                        <div className="w-1/2 flex-none">
                          <FormField name="approvedOffer">
                            {(control) => (
                              <NumberInput
                                label={t('projectContract.offerApprovement')}
                                disabled={
                                  (state !== 'Optional' && state !== 'Received' && state !== 'Commissioned'
                                  && state !== 'Approved') || !canWrite
                                }
                                icon={<EstimateIcon className="h-6 w-6" />}
                                onBlur={handleCalculateValuesChanged}
                                {...control}
                              />
                            )}
                          </FormField>
                        </div>
                      </div>
                      <div className="flex gap-0.5">
                        <div className="w-1/2 flex-none">
                          <FormField name="discount">
                            {(control) => (
                              <NumberInput
                                label={t('projectContract.discount')}
                                icon={<PercentageIcon className="h-6 w-6" />}
                                disabled={state === 'Reserve' ||!canWrite}
                                onBlur={handleCalculateValuesChanged}
                                {...control}
                              />
                            )}
                          </FormField>
                        </div>
                        <div className="w-1/2 flex-none">
                          <FormField name="contractValue">
                            {(control) => (
                              <NumberInput
                                label={t('projectContract.contractValue')}
                                icon={<EstimateIcon className="h-6 w-6" />}
                                disabled
                                {...control}
                              />
                            )}
                          </FormField>
                        </div>
                      </div>
                    </div>
                    <div className="border-t-2 border-t-gray-200 pt-3 flex gap-0.5">
                      <div className="w-1/2 flex-none">
                        <FormField name="adjustmentValue">
                          {(control) => (
                            <NumberInput
                              label={t('projectContract.adjustmentValue')}
                              icon={<CurrencyExchangeIcon className="h-6 w-6" />}
                              disabled={
                                state === 'Canceled' || state === 'Budgeted' || state === 'Rejected' || !canWrite
                              }
                              onBlur={handleCalculateValuesChanged}
                              {...control}
                            />
                          )}
                        </FormField>
                      </div>
                      <div className="w-1/2 flex-none">
                        <FormField name="forecastValue">
                          {(control) => (
                            <NumberInput
                              label={t('projectContract.forecastValue')}
                              icon={<PaidSearchIcon className="h-6 w-6" />}
                              disabled={
                                state === 'Canceled' || state === 'Budgeted' || state === 'Rejected' || !canWrite
                              }
                              onBlur={handleForecastValueChanged}
                              {...control}
                            />
                          )}
                        </FormField>
                      </div>
                    </div>
                  </div>
                )}
              </FormWatch>
              {canWrite && <EditUserDefinedFields
                elementId={title?.id ?? ''}
                type="ContractTitle"
                setUpdatePayload={setUdfUpdatePayload}
                isSubmitted={isFormSubmitted}
                updateIsValid={setCustomFieldsAreValid}
                setIsLoading={setIsLoadingUserDefinedFields}
               />}

              {title && (
                <div className="mx-8 mb-8 relative">
                  <BudgetAssignment
                    budgetAssignments={title?.budgetAssignments}
                    id={title?.id}
                    disabled={!canWrite || !title?.canBeBudgeted}
                    canBeBudgeted={title?.canBeBudgeted}
                    updateBudgetAssignments={({ added, updated, deleted }) => {
                      setAddedBudgetAssignments(added);
                      setUpdatedBudgetAssignments(updated);
                      setDeletedBudgetAssignments(deleted);
                    }}
                    validBudget={setValidBudget}
                    setIsBudgetAssignmentPopoverOpen={(open) => setIsBudgetAssignmentPopoverOpen(open)}
                    cannotBeBudgetedMessage={t('projectContract.titleCannotBeBudgetedMessage')}
                    contextMenu={[
                      {
                        label: t('projectContract.budgetinglevel'),
                        subtitle: t('projectContract.budgetinglevel.description'),
                        onClick: handleShowBudgetLevelModal,
                        isDisabled: !title.budgetAssignments.length
                      },
                    ]}
                  />
                </div>
              )}
            </div>
          )}
        </SlideOver.Content>
        <SlideOver.Controls>
          <div className={cn('w-full flex', canDelete && title ? 'justify-between' : 'justify-end')}>
            {canDelete && title && (
              <Button onClick={handleDelete} className="mr-2" variant="warning">
                {t('common.delete')}
              </Button>
            )}
            <div className="flex">
              <Button onClick={() => onClose(false)} className="mr-2" variant="secondary">
                {t('common.cancel')}
              </Button>
              {canWrite && (
                <Button variant="primary" formSubmit={true} innerRef={submitRef} onClick={() => setIsFormSubmitted(true)}>
                  {t('common.save')}
                </Button>
              )}
            </div>
          </div>
        </SlideOver.Controls>
      </Form>

      <Modal isOpen={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)}>
        {title && (
          <ContractTitleDeleteModal
            onClose={(close) => {
              setIsDeleteModalOpen(false);
              if (close) {
                onClose(close);
              }
            }}
            title={title}
          />
        )}
      </Modal>
      <Modal isOpen={showBudgetLevelModal} onClose={() => setShowBudgetLevelModal(false)}>
        <BudgetLevelModal contract={contract} sourceLevel='Title' hasCommitment={contract.commitmentId !== (null || undefined)} onClose={() => setShowBudgetLevelModal(false)}/>
      </Modal>
    </>
  );
};
