import {
  Button,
  ComboSelect,
  ComboSelectAdditionalOption,
  ComboSelectOption,
  ContactIcon,
  DocumentViewerFileDataInlineEdit,
  DocumentViewerFileDataSet,
  FormField,
  FormRefHandle,
  // GuideDialog,
  LoadingIndicator,
} from '@client/shared/toolkit';
import React, { RefObject, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AiEvalValuesReadModel,
  SelectCompanyBranchReadModel,
  ShortContractReadModel,
  useApiGetProjectSelectCompanyBranchesQuery,
} from '@client/shared/api';
import { useLoadedProjectId } from '@client/project/store';
import { InvoiceCreateFormValidationValues } from '../InvoiceCreateFormValidationValues';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { BranchData } from '.';
import { AddCompanyModal } from '../..';

export const findBranchByIbanOrName = (branches: SelectCompanyBranchReadModel[], name?: string, iban?: string) => {
  return branches?.find((branch) => {
    if (name && branch.name.toLowerCase().split(' ').join('') === name.toLowerCase().split(' ').join('')) {
      return true;
    }
    if (branch.bankAccounts && iban) {
      const foundBankAccount = branch.bankAccounts.find((bankAccount) => {
        return bankAccount.iban?.toLowerCase().split(' ').join('') === iban.toLowerCase().split(' ').join('');
      });
      if (foundBankAccount) {
        return true;
      }
    }
    return false;
  });
};

interface InvoiceDocumentReviewContractorProps {
  fileData?: AiEvalValuesReadModel | null;
  formRef?: RefObject<FormRefHandle<InvoiceCreateFormValidationValues>>;
  selectedContract?: ShortContractReadModel | null;
}

export const InvoiceDocumentReviewContractorAndClient = (props: InvoiceDocumentReviewContractorProps) => {
  const { fileData, formRef, selectedContract } = props;
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();
  const canWrite = useValidateProjectPermission(['INVOICE_WRITE'], loadedProjectId ?? '');

  // selected contractor from branches list by user
  const [selectedContractor, setSelectedContractor] = useState<SelectCompanyBranchReadModel | null>(null);
  // selected client from branches list by user
  const [selectedClient, setSelectedClient] = useState<SelectCompanyBranchReadModel | null>(null);

  const [createCompanyField, setCreateCompanyField] = useState<'contractor' | 'client'>('contractor');
  const [createdCompany, setCreatedCompany] = useState<string | undefined>(undefined);
  const [isOpenCreateCompanyModal, setIsOpenCreateCompanyModal] = useState(false);
  const [allowChangeMode, setAllowChangeMode] = useState(true);
  const labelClient = t('projectControl.auditInvoiceCustomer');
  const labelContractor = t('projectControl.auditInvoiceContractor');

  const { data: branches, isFetching: isLoadingBranches } = useApiGetProjectSelectCompanyBranchesQuery(
    {
      projectId: loadedProjectId ?? '',
    },
    {
      skip: !loadedProjectId,
    },
  );

  // original mapped contractor from file data an branches list
  const [originalContractor, setOriginalContractor] = useState<SelectCompanyBranchReadModel | null>(null);
  // original mapped client from file data an branches list
  const [originalClient, setOriginalClient] = useState<SelectCompanyBranchReadModel | null>(null);

  const companiesOptions = useMemo(() => {
    const options: ComboSelectOption[] = [];
    const companies = [] as ComboSelectOption[];
    branches?.forEach((branch) => {
      if (!companies.some((company) => company.value === branch.companyId)) {
        companies.push({
          label: branch.companyName,
          value: branch.companyId,
        });
      }
    });

    companies.forEach((company) => {
      const companyBranches = branches?.filter((branch) => branch.companyId === company.value) ?? [];
      const isSingleBranch = companyBranches?.length === 1;
      const isPrivatePerson = companyBranches?.some((branch) => branch.companyType === 'PrivatePerson');

      const label = isPrivatePerson
        ? companyBranches[0]?.name
        : isSingleBranch
          ? `${company.label} - ${companyBranches[0]?.name}`
          : company.label;
      const value = isSingleBranch || isPrivatePerson ? companyBranches[0]?.id : company.value;
      options.push({
        label: label,
        value: value,
        options:
          isSingleBranch || isPrivatePerson
            ? []
            : companyBranches?.map((branch) => ({
                label: branch.name,
                value: branch.id,
              })),
      });
    });

    return options;
  }, [branches]);

  useEffect(() => {
    if (companiesOptions.length && fileData) {
      const currentValues = formRef?.current?.getValues();
      if (!currentValues?.invoicingPartyId && fileData?.contractorName && branches) {
        // search for the name or iban in branch
        const foundBranch = findBranchByIbanOrName(
          branches,
          fileData?.contractorName,
          fileData?.contractorBankDetails?.iban,
        );

        if (foundBranch) {
          setOriginalContractor(foundBranch);
          if (!selectedContract) {
            const foundCompany = companiesOptions.find((option) => option.value === foundBranch.id);
            if (foundCompany) {
              formRef?.current?.setValue('invoicingPartyId', foundCompany.value);
            }
          }
        }
      }
      if (!currentValues?.invoiceRecipientId && fileData?.customerName && branches) {
        const foundBranch = findBranchByIbanOrName(branches, fileData?.customerName);
        if (foundBranch) {
          setOriginalClient(foundBranch);
          if (!selectedContract) {
            const foundCompany = companiesOptions.find((option) => option.value === foundBranch.id);
            if (foundCompany) {
              formRef?.current?.setValue('invoiceRecipientId', foundCompany.value);
            }
          }
        }
      }
    }
  }, [formRef, companiesOptions, fileData, branches, selectedContract]);

  useEffect(() => {
    setSelectedContractor(originalContractor);
  }, [originalContractor]);

  useEffect(() => {
    setSelectedClient(originalClient);
  }, [originalClient]);

  useEffect(() => {
    if (createdCompany && formRef?.current && branches?.length) {
      const foundBranch = branches?.find(
        (branch) => branch.id === createdCompany || branch.companyId === createdCompany,
      );
      if (foundBranch) {
        if (createCompanyField === 'contractor') {
          setSelectedContractor(foundBranch);
          formRef?.current?.setValue('invoicingPartyId', foundBranch.id);
        } else {
          setSelectedClient(foundBranch);
          formRef?.current?.setValue('invoiceRecipientId', foundBranch.id);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdCompany, branches]);

  // contractor and client are coming from contract if fileData
  useEffect(() => {
    if (selectedContract) {
      if (selectedContract.contractorId) {
        const foundContractor = branches?.find((branch) => branch.id === selectedContract.contractorId);
        setSelectedContractor(foundContractor ?? null);
      } else {
        setSelectedContractor(null);
      }
      if (selectedContract.clientId) {
        const foundClient = branches?.find((branch) => branch.id === selectedContract.clientId);
        setSelectedClient(foundClient ?? null);
      } else {
        setSelectedClient(null);
      }
    }
  }, [selectedContract, branches]);

  return isLoadingBranches ? (
    <LoadingIndicator text={t('common.loading')} />
  ) : (
    <>
      {/*--------- CONTRACTOR -------*/}
      <div className="py-4">
        {/* <GuideDialog
          show
          placement="bottom-end"
          header={<img src="/assets/questionmark.png" className="w-full h-full" alt="Questionmark" />}
          title={t('projectInvoice.adoptNewAddress')}
          description={t('projectInvoice.adoptNewAddressDescription')}
          controls={
            <div className="p-1 mb-1 mt-5 w-full flex justify-between">
              <Button variant="secondary" className="mr-2" >
                {t('common.close')}
              </Button>
              <Button variant="primary" >
                {t('common.update')}
              </Button>
            </div>
          }
        > */}
          <DocumentViewerFileDataInlineEdit
            allowChangeMode={allowChangeMode}
            noHover={selectedContractor ? false : true}
            toggleContent={
              canWrite ? (
                <FormField name="invoicingPartyId">
                  {(control) => (
                    <ComboSelect
                      label={labelContractor}
                      icon={<ContactIcon className="h-6 w-6" />}
                      pageOptions={true}
                      options={companiesOptions}
                      additionalOptionOnClick={() => {
                        setIsOpenCreateCompanyModal(true);
                        setCreateCompanyField('contractor');
                      }}
                      additionalOption={<ComboSelectAdditionalOption label={t('projectControl.createNewContractor')} />}
                      disabled={!canWrite}
                      {...control}
                      nullable
                      handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                      onChange={(branchId) => {
                        const foundContractor = branches?.find((branch) => branch.id === branchId);
                        setSelectedContractor(foundContractor ?? null);
                        control.onChange(branchId);
                        formRef?.current?.setValue('contractId', null);
                      }}
                    />
                  )}
                </FormField>
              ) : undefined
            }
          >
            <div className="divide-y-2">
              {selectedContractor ? (
                <BranchData
                  branch={selectedContractor}
                  label={labelContractor}
                  branchNameClassName={originalContractor?.id !== selectedContractor?.id ? 'text-secondary' : undefined}
                  // notMatched
                />
              ) : (
                <DocumentViewerFileDataSet label={labelContractor}>
                  <div className="w-full flex justify-center">
                    <Button variant="secondary">{t('common.add')}</Button>
                  </div>
                </DocumentViewerFileDataSet>
              )}
            </div>
          </DocumentViewerFileDataInlineEdit>
        {/* </GuideDialog> */}
      </div>

      {/*--------- CLIENT -------*/}
      <div className="py-4">
        <DocumentViewerFileDataInlineEdit
          allowChangeMode={allowChangeMode}
          noHover={selectedClient ? false : true}
          toggleContent={
            canWrite ? (
              <FormField name="invoiceRecipientId">
                {(control) => (
                  <ComboSelect
                    label={labelClient}
                    icon={<ContactIcon className="h-6 w-6" />}
                    pageOptions={true}
                    options={companiesOptions}
                    additionalOptionOnClick={() => {
                      setIsOpenCreateCompanyModal(true);
                      setCreateCompanyField('client');
                    }}
                    additionalOption={<ComboSelectAdditionalOption label={t('projectControl.createNewContractor')} />}
                    disabled={!canWrite}
                    {...control}
                    nullable
                    handlePopoverVisibility={(isOpen) => setAllowChangeMode(!isOpen)}
                    onChange={(branchId) => {
                      const foundContract = branches?.find((branch) => branch.id === branchId);
                      setSelectedClient(foundContract ?? null);
                      control.onChange(branchId);
                      formRef?.current?.setValue('contractId', null);
                    }}
                  />
                )}
              </FormField>
            ) : undefined
          }
        >
          <div className="divide-y-2">
            {selectedClient ? (
              <BranchData
                branch={selectedClient}
                label={labelClient}
                branchNameClassName={originalClient?.id !== selectedClient?.id ? 'text-secondary' : undefined}
              />
            ) : (
              <DocumentViewerFileDataSet label={labelClient}>
                <div className="w-full flex justify-center">
                  <Button variant="secondary">{t('common.add')}</Button>
                </div>
              </DocumentViewerFileDataSet>
            )}
          </div>
        </DocumentViewerFileDataInlineEdit>
      </div>

      <AddCompanyModal
        isOpen={isOpenCreateCompanyModal}
        onClose={(branchId) => {
          setCreatedCompany(branchId);
          setIsOpenCreateCompanyModal(false);
        }}
        field={createCompanyField}
        invoiceDocumentFileData={fileData}
      />
    </>
  );
};
