import {
  ShortCommitmentReadModel,
  ElementTimelineReadModel,
  useApiPostUpdateContractMutation,
  useApiGetProjectSelectCompanyBranchesQuery,
  useApiGetProjectInvoiceCalculationSchemesQuery,
  useApiGetContractQuery,
  WorkflowReadModel,
  useApiGetWorkflowsQuery,
  CalculationRuleValueOverridePayload, 
  InvoiceCalculationSchemeType,
  ContractReadModel,
  UserDefinedFieldPayload
} from '@client/shared/api';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ContractEditFormValidationSchema, ContractEditFormValidationValues } from './ContractEditFormValidationValues';
import { formatDateOnly, isEmpty, safeMutation } from '@client/shared/utilities';
import {
  Button,
  Form,
  TextInput,
  FormField,
  SlideOver,
  SlideOverOnCloseProps,
  ContractNumberIcon,
  ContactIcon,
  PriceTagIcon,
  BaseSelect,
  BaseSelectOption,
  LoadingIndicator,
  NumberInput,
  DiscountIcon,
  LinkIcon,
  PercentageIcon,
  ComboSelect,
  ComboSelectOption,
  SlideOverTitle, WorkflowIcon,
  DatePicker
} from '@client/shared/toolkit';
import { useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { useValidateProjectPermission } from '@client/shared/permissions';
import { ClockIcon } from '@heroicons/react/24/outline';
import {
  TimeLineDistribution,
  timelineReadModelToPayload,
  ProjectTaxPickerInput,
  EditUserDefinedFields
} from '@client/project/shared';
import cn from 'classnames';
import { ContractIcs } from './ContractIcs';
// import { AddCompanyModal } from './AddCompanyModal';

interface ContractEditSlideOverProps extends SlideOverOnCloseProps {
  contractId: string;
  selectedContract?: ContractReadModel | null;
  commitments: ShortCommitmentReadModel[] | null;
  field?: string | null;
}

export const ContractEditSlideOver = ({ onClose, contractId, selectedContract, commitments, field }: ContractEditSlideOverProps) => {
  const { t } = useTranslation();

  const submitRef = useRef<HTMLButtonElement>(null);
  const [postUpdateContract, { isLoading }] = useApiPostUpdateContractMutation();

  const [commitmentsOptions, setCommitmentsOptions] = useState<BaseSelectOption[]>([]);

  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();

  const [totalBudget, setTotalBudget] = useState<number>(0);
  const [contract, setContract] = useState<ContractReadModel | null>(selectedContract ?? null);
  const [elementTiming, setElementTiming] = useState<ElementTimelineReadModel | undefined | null>();
  // const [isOpenAddCompanyModal, setIsOpenAddCompanyModal] = useState<boolean>(false);

  const [singleOverrideValues, setSingleOverrideValues] = useState<CalculationRuleValueOverridePayload[]>([]);
  const [cumulatedOverrideValues, setCumulatedOverrideValues] = useState<CalculationRuleValueOverridePayload[]>([]);
  const [advancePaymentOverrideValues, setAdvancePaymentOverrideValues] = useState<CalculationRuleValueOverridePayload[]>([]);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const [customFieldsAreValid, setCustomFieldsAreValid] = useState(true)
  const [udfUpdatePayload, setUdfUpdatePayload] = useState<UserDefinedFieldPayload[] | undefined>();

  const [isHighlighted, setIsHighlighted] = useState(!!field)

  const canWrite =
    useValidateProjectPermission(['CONTRACT_WRITE'], loadedProjectId ?? '') && (!contract || !contract.isPxContract);

  const contractorFieldRef = useRef<HTMLInputElement>(null);
  const clientFieldRef = useRef<HTMLInputElement>(null);
  const codeFieldRef = useRef<HTMLInputElement>(null);
  const nameFieldRef = useRef<HTMLInputElement>(null);

  const [isLoadingUserDefinedFields, setIsLoadingUserDefinedFields] = useState(false)

  useEffect(() => {
    if (field && contractorFieldRef.current && field === 'contractor') {
      contractorFieldRef.current?.scrollIntoView({ behavior: 'smooth' });
      contractorFieldRef.current?.focus();
    } else if (field && clientFieldRef.current && field === 'client') {
      clientFieldRef.current?.scrollIntoView({ behavior: 'smooth' });
      clientFieldRef.current?.focus();
    } else if (field && codeFieldRef.current && field === 'code') {
      codeFieldRef.current?.scrollIntoView({ behavior: 'smooth' });
      codeFieldRef.current?.focus();
    } else if (field && nameFieldRef.current && field === 'name') {
      nameFieldRef.current?.scrollIntoView({ behavior: 'smooth' });
      nameFieldRef.current?.focus();
    }
  } , [field, contractorFieldRef, clientFieldRef, codeFieldRef, nameFieldRef])


  const { data: branches, isFetching: isLoadingBranches } = useApiGetProjectSelectCompanyBranchesQuery(
    {
      projectId: loadedProjectId ?? '',
    },
    {
      skip: !loadedProjectId,
    },
  );

  const companiesOptions = useMemo(() => {
    const options: ComboSelectOption[] = [];

    if (contract?.contractor && !branches?.some(x => x.id === contract.contractor?.id)){
      options.push({
        label: `${contract.contractor?.companyName} - ${contract.contractor?.name}`,
        value: contract.contractor?.id,
      })
    }

    if (contract?.client && !branches?.some(x => x.id === contract.client?.id)){
      options.push({
        label: `${contract.client?.companyName} - ${contract.client?.name}`,
        value: contract.client?.id,
      })
    }

    const companies = [] as ComboSelectOption[];
    branches?.forEach((branch) => {
      if (!companies.some((company) => company.value === branch.companyId)) {
        companies.push({
          label: branch.companyName,
          value: branch.companyId,
        });
      }
    });

    companies.forEach((company) => {
      const companyBranches = branches?.filter((branch) => branch.companyId === company.value) ?? [];
      const isSingleBranch = companyBranches?.length === 1;
      const isPrivatePerson = companyBranches?.some((branch) => branch.companyType === 'PrivatePerson');

      const label = isPrivatePerson
        ? companyBranches[0]?.name
        : isSingleBranch
          ? `${company.label} - ${companyBranches[0]?.name}`
          : company.label;
      const value = isSingleBranch || isPrivatePerson ? companyBranches[0]?.id : company.value;
      options.push({
        label: label,
        value: value,
        options:
          isSingleBranch || isPrivatePerson
            ? []
            : companyBranches?.map((branch) => ({
                label: branch.name,
                value: branch.id,
              })),
      });
    });

    return options;
  }, [branches, contract]);

  const { data: loadedContract, isFetching: isLoadingContract } = useApiGetContractQuery(
    {
      projectId: loadedProjectId ?? '',
      calculationModelId: loadedVariantId ?? '',
      contractId: contractId
    },
    {
      skip: !loadedProjectId || !loadedVariantId || !isEmpty(selectedContract),
    },
  );

  useEffect(() => {
    if (loadedContract) {
      setContract(loadedContract)
    }
  }, [loadedContract]);

  const [workflows, setWorkflows] = useState<WorkflowReadModel[]>([])
  const { data: availableWorkflows, isFetching: isLoadingWorkflows } = useApiGetWorkflowsQuery()

  useEffect(() => {
    if (typeof availableWorkflows !== 'undefined' && availableWorkflows !== null) {
      setWorkflows(availableWorkflows.workflows)
    }
  }, [availableWorkflows]);

  const workflowOptions: BaseSelectOption[] = useMemo(() => {
    const options: BaseSelectOption[] = []
    workflows.forEach((workflow) => {
      if (workflow.isContractWorkflow) {
        options.push( {
          label: workflow.name,
          value: workflow.name
        })
      }
    })
    return options
  }, [workflows])

  const { data: sheets, isFetching: isFetchingICS } = useApiGetProjectInvoiceCalculationSchemesQuery(
    { projectId: loadedProjectId ?? '' },
    { skip: !loadedProjectId },
  )

  useEffect(() => {
    const options = commitments?.map((commitment) => {
      return {
        label: commitment.name,
        value: commitment.id,
      };
    });
    setCommitmentsOptions(options ?? []);

    const assignedBudget =
      contract?.budgetAssignments.reduce((sum, budgetAssignment) => {
        return sum + budgetAssignment.budgetNet;
      }, 0) ?? 0;
    setTotalBudget(assignedBudget);

    setElementTiming(contract?.elementTimeline);
  }, [commitments, contract, t]);

  const handleSubmit = async (data: ContractEditFormValidationValues) => {
    if (contract?.id && loadedVariantId && loadedProjectId && customFieldsAreValid) {
      try {
        await safeMutation(
          postUpdateContract,
          {
            contractId: contract.id,
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: {
              code: data.code,
              name: data.name,
              description: data.description,
              contractDate: data.contractDate ? formatDateOnly(data.contractDate) : null,
              vat: data.vat,
              discount: data.discount,
              dueDateDeadline: data.dueDateDeadline,
              cashDiscountDeadline: data.cashDiscountDeadline,
              contractorId: data.contractorId,
              clientId: data.clientId,
              commitmentId: data.commitmentId,
              elementTimeline: elementTiming?.distribution ? timelineReadModelToPayload(elementTiming) : null,
              addedBudgetAssignments: [],
              updatedBudgetAssignments: [],
              deletedBudgetAssignments: [],
              cumulatedInvoiceCalculationScheme: data.cumulatedInvoiceCalculationScheme
                ? { invoiceCalculationSchemeId: data.cumulatedInvoiceCalculationScheme, valueOverrides: cumulatedOverrideValues }
                : null,
              singleInvoiceCalculationScheme: data.singleInvoiceCalculationScheme
                ? { invoiceCalculationSchemeId: data.singleInvoiceCalculationScheme, valueOverrides: singleOverrideValues }
                : null,
              advancePaymentInvoiceCalculationScheme: data.advancePaymentInvoiceCalculationScheme
                ? { invoiceCalculationSchemeId: data.advancePaymentInvoiceCalculationScheme, valueOverrides: advancePaymentOverrideValues }
                : null,
              workflow: data.workflow,
              userDefinedFieldsPayload: udfUpdatePayload,
            },
          },
          isLoading,
        );
        onClose(true);
      } catch (e) {
        console.error(e);
      }
    }
  };

  const defaultFormValues = useMemo(() => {
    return {
      id: contract?.id ?? '',
      name: contract?.name ?? '',
      code: contract?.code ?? '',
      description: contract?.description,
      contractDate: contract?.contractDate ? new Date(contract.contractDate) : undefined,
      contractorId: contract?.contractor?.id ?? null,
      clientId: contract?.client?.id ?? null,
      commitmentId: contract?.commitmentId ?? null,
      vat: contract?.vat ?? 0,
      discount: contract?.discount ?? 0,
      dueDateDeadline: contract?.dueDateDeadline ?? undefined,
      cashDiscountDeadline: contract?.cashDiscountDeadline ?? undefined,
      singleInvoiceCalculationScheme:
      contract?.invoiceCalculationSchemes.find((scheme) => scheme.invoiceCalculationScheme.type === 'Single')
          ?.invoiceCalculationScheme.invoiceCalculationSchemeId ?? null,
      cumulatedInvoiceCalculationScheme:
      contract?.invoiceCalculationSchemes.find((scheme) => scheme.invoiceCalculationScheme.type === 'Cumulated')
          ?.invoiceCalculationScheme.invoiceCalculationSchemeId ?? null,
      advancePaymentInvoiceCalculationScheme:
      contract?.invoiceCalculationSchemes.find((scheme) => scheme.invoiceCalculationScheme.type === 'AdvancePayment')
          ?.invoiceCalculationScheme.invoiceCalculationSchemeId ?? null,
      workflow: contract?.workflow ?? null
    }
  }, [contract]);

  useEffect(() => {
    const handleOutsideClick = () => {
      setIsHighlighted(false)
    }
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [])

  useEffect(() => {
    if (contract?.invoiceCalculationSchemes) {
      contract.invoiceCalculationSchemes.forEach((scheme) => {
        if (scheme.invoiceCalculationScheme.type === 'Single' && scheme.valueOverrides) {
          const overridesPayload: CalculationRuleValueOverridePayload[] = scheme.valueOverrides.length ? scheme.valueOverrides.map((v) => {
            return {
              calculationRuleId: v.calculationRule.calculationRuleId,
              percentage: v.percentage,
              value: v.value,
              vat: v.vat
            }
          }) : []
          setSingleOverrideValues(overridesPayload)
        } else if (scheme.invoiceCalculationScheme.type === 'Cumulated' && scheme.valueOverrides) {
          const overridesPayload: CalculationRuleValueOverridePayload[] = scheme.valueOverrides.length ? scheme.valueOverrides.map((v) => {
            return {
              calculationRuleId: v.calculationRule.calculationRuleId,
              percentage: v.percentage,
              value: v.value,
              vat: v.vat
            }
          }) : []
          setCumulatedOverrideValues(overridesPayload)
        } else if (scheme.invoiceCalculationScheme.type === 'AdvancePayment' && scheme.valueOverrides) {
          const overridesPayload: CalculationRuleValueOverridePayload[] = scheme.valueOverrides.length ? scheme.valueOverrides.map((v) => {
            return {
              calculationRuleId: v.calculationRule.calculationRuleId,
              percentage: v.percentage,
              value: v.value,
              vat: v.vat
            }
          }) : []
          setAdvancePaymentOverrideValues(overridesPayload)
        }
      })
    }
  }, [contract?.invoiceCalculationSchemes]);

  const originalOverrideValues = useMemo(() => {
    return {
      Single: contract?.invoiceCalculationSchemes.find((scheme) => scheme.invoiceCalculationScheme.type === 'Single')?.valueOverrides ?? [],
      Cumulated: contract?.invoiceCalculationSchemes.find((scheme) => scheme.invoiceCalculationScheme.type === 'Cumulated')?.valueOverrides ?? [],
      AdvancePayment: contract?.invoiceCalculationSchemes.find((scheme) => scheme.invoiceCalculationScheme.type === 'AdvancePayment')?.valueOverrides ?? [],
    }
  }, [contract?.invoiceCalculationSchemes])

  const overrideRules = useMemo(() => {
    return {
      Single: singleOverrideValues,
      Cumulated: cumulatedOverrideValues,
      AdvancePayment: advancePaymentOverrideValues,
    };
  }, [singleOverrideValues, cumulatedOverrideValues, advancePaymentOverrideValues]);

  const icsSchemes = useMemo(() => {
    return {
      Single:
      contract?.invoiceCalculationSchemes.find((scheme) => scheme.invoiceCalculationScheme.type === 'Single')
          ?.invoiceCalculationScheme ?? null,
      Cumulated:
      contract?.invoiceCalculationSchemes.find((scheme) => scheme.invoiceCalculationScheme.type === 'Cumulated')
          ?.invoiceCalculationScheme ?? null,
      AdvancePayment:
      contract?.invoiceCalculationSchemes.find((scheme) => scheme.invoiceCalculationScheme.type === 'AdvancePayment')
          ?.invoiceCalculationScheme ?? null,
    };
  }, [contract?.invoiceCalculationSchemes]);

  const updateOverrideValues = useCallback((type: InvoiceCalculationSchemeType, values: CalculationRuleValueOverridePayload[]) => {
    if (type === 'Single') {
      setSingleOverrideValues(values);
    } else if (type === 'Cumulated') {
      setCumulatedOverrideValues(values);
    } else if (type === 'AdvancePayment') {
      setAdvancePaymentOverrideValues(values);
    }
  }, []);

  /* TODO insert as soon as workflows can be manually created
  const goToWorkflowsView = () => {
    navigate(ROUTES_CONFIG.SETTINGS_WORKFLOWS.path)
  }*/

  return (
    <>
      {(isLoadingBranches || isLoadingWorkflows || isLoadingContract || isFetchingICS || isLoadingUserDefinedFields) && (
        <LoadingIndicator text={t('projectContract.fetchingContractLoadingIndicator')} mode="overlay" />
      )}
      {(isLoading) && (
        <LoadingIndicator text={t('common.saving')} mode="overlay-window" />
      )}
      <Form<ContractEditFormValidationValues>
        onSubmit={handleSubmit}
        validationSchema={ContractEditFormValidationSchema}
        defaultValues={defaultFormValues}
        className="flex flex-col w-full h-full"
      >
        <SlideOver.Header
          title={contract?.name ?? ''}
          subTitle={t('projectContract.editContract')}
          onClose={onClose}
          backgroundClassName="bg-gray-900"
        />

        <SlideOver.Content
          onKeyEnter={() => {
            submitRef.current?.click();
          }}
        >
          <div className="m-8 mb-0">
            <div className="divide-gray-100 divide-y">
              <div
                className={cn('relative before:content-[""] before:absolute before:left-0 before-top-0 before:w-1 before:h-full before:bg-slate-600 before:transition-opacity before:duration-300 before:z-10', {
                  'before:opacity-1': field === 'contractor' && isHighlighted,
                  'before:opacity-0': field !== 'contractor' || !isHighlighted,
                })}
              >
                <FormField name="contractorId">
                  {(control) => (
                    <ComboSelect
                      label={t('projectContract.contractor')}
                      icon={<ContactIcon className="h-6 w-6" />}
                      options={companiesOptions}
                      disabled={!canWrite}
                      inputRef={contractorFieldRef}
                      tabIndex={field === 'contractor' ? 1 : undefined}
                      pageOptions={true}
                      nullable
                      // additionalOption={
                      //   <div
                      //     className="w-full h-full flex justify-between items-center"
                      //     onClick={() => setIsOpenAddCompanyModal(true)}
                      //   >
                      //     <span className="italic text-gray-400">{t('projectContract.addNewCompany')}</span>
                      //     <PencilIcon className="ml-auto h-5 w-5" />
                      //   </div>
                      // }
                      // children={
                      //   <div className="px-3 py-2 flex justify-between text-xs font-semibold">
                      //     <span className="ml-7">
                      //       {control.value ? branches?.find((x) => x.id === control.value)?.persons[0]?.personName ?? '' : ''}
                      //     </span>
                      //     <span className="flex items-center text-green-500">
                      //       <UploadToCloudIcon className="h-4 w-4 mr-2" />
                      //       {t('uploadPortal.uploadPortalActive')}
                      //     </span>
                      //   </div>
                      // }
                      {...control}
                    />
                  )}
                </FormField>
              </div>
              <div
                className={cn('relative before:content-[""] before:absolute before:left-0 before-top-0 before:w-1 before:h-full before:bg-slate-600 before:transition-opacity before:duration-300 before:z-10', {
                  'before:opacity-1 ': field === 'code' && isHighlighted,
                  'before:opacity-0': field !== 'code' || !isHighlighted,
                })}
              >
                <FormField name="code">
                  {(control) => (
                    <TextInput
                      label={t('projectContract.contractCode')}
                      icon={<ContractNumberIcon className="h-6 w-6" />}
                      disabled={!canWrite}
                      inputRef={codeFieldRef}
                      {...control}
                    />
                  )}
                </FormField>
              </div>
              <div
                className={cn('relative before:content-[""] before:absolute before:left-0 before-top-0 before:w-1 before:h-full before:bg-slate-600 before:transition-opacity before:duration-300 before:z-10', {
                  'before:opacity-1 ': field === 'name' && isHighlighted,
                  'before:opacity-0': field !== 'name' || !isHighlighted,
                })}
              >
                <FormField name="name">
                  {(control) => (
                    <TextInput
                      label={t('projectContract.contractName')}
                      icon={<PriceTagIcon className="h-6 w-6" />}
                      disabled={!canWrite}
                      inputRef={nameFieldRef}
                      {...control}
                    />
                  )}
                </FormField>
              </div>
              <FormField name="description">
                {(control) => (
                  <TextInput
                    label={t('projectContract.contractDescription')}
                    inputType="textarea"
                    className="font-normal"
                    disabled={!canWrite}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="contractDate">
                {(control) => (
                  <DatePicker
                    label={t('projectContract.contractDate')}
                    className="font-normal"
                    disabled={!canWrite}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="vat">
                {(control) => (
                  <ProjectTaxPickerInput
                    label={t('projectContract.taxRate')}
                    icon={<PercentageIcon className="h-6 w-6" />}
                    disabled={!canWrite}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="discount">
                {(control) => (
                  <NumberInput
                    label={t('projectContract.discount')}
                    icon={<DiscountIcon className="h-6 w-6" />}
                    className="font-normal"
                    disabled={!canWrite}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="dueDateDeadline">
                {(control) => (
                  <NumberInput
                    label={t('projectContract.dueDateDeadline')}
                    icon={<ClockIcon className="h-6 w-6" />}
                    className="font-normal"
                    disabled={!canWrite}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="cashDiscountDeadline">
                {(control) => (
                  <NumberInput
                    label={t('projectContract.cashDiscountDeadline')}
                    icon={<ClockIcon className="h-6 w-6" />}
                    className="font-normal"
                    disabled={!canWrite}
                    {...control}
                  />
                )}
              </FormField>
              <div
                className={cn('relative before:content-[""] before:absolute before:left-0 before-top-0 before:w-1 before:h-full before:bg-slate-600 before:transition-opacity before:duration-300 before:z-10', {
                  'before:opacity-1 ': field === 'client' && isHighlighted,
                  'before:opacity-0': field !== 'client' || !isHighlighted,
                })}
              >
                <FormField name="clientId">
                  {(control) => (
                    <ComboSelect
                      label={t('projectContract.contractClient')}
                      icon={<ContactIcon className="h-6 w-6" />}
                      options={companiesOptions}
                      disabled={!canWrite}
                      nullable
                      inputRef={clientFieldRef}
                      pageOptions={true}
                      tabIndex={field === 'client' ? 1 : undefined}
                      {...control}
                    />
                  )}
                </FormField>
              </div>
              <FormField name="commitmentId">
                {(control) => (
                  <BaseSelect
                    label={t('projectContract.commitment')}
                    icon={<LinkIcon className="h-6 w-6" />}
                    options={commitmentsOptions}
                    disabled={!canWrite}
                    nullable
                    {...control}
                  />
                )}
              </FormField>
            </div>
          </div>
          {/*
          <div ref={budgetFieldRef} className="mx-8">
            <BudgetAssignment
              budgetAssignments={contract?.budgetAssignments}
              id={contract?.id}
              disabled={!canWrite || !contract?.canBeBudgeted}
              canBeBudgeted={contract?.canBeBudgeted}
              updateBudgetAssignments={({ added, updated, deleted }) => {
                setAddedBudgetAssignments(added);
                setUpdatedBudgetAssignments(updated);
                setDeletedBudgetAssignments(deleted);
              }}
              validBudget={(valid) => setValidBudget(valid)}
              setIsBudgetAssignmentPopoverOpen={(open) => setIsBudgetAssignmentPopoverOpen(open)}
              cannotBeBudgetedMessage={
                commitmentHasBudgeting
                  ? t('projectContract.contractBudgetedByCommitmentMessage')
                  : t('projectContract.contractCannotBeBudgetedMessage')
              }
              focused={field === 'budget' && isHighlighted}
            />
          </div> */}
          <div className="mx-8">
            <SlideOverTitle title={t('projectCalculate.timeline')} />
            <TimeLineDistribution
              totalValue={totalBudget}
              variantId={loadedVariantId ?? ''}
              timing={elementTiming}
              disabled={!canWrite}
              disabledDistributionTypes={['Effective']}
              onChange={(value) => setElementTiming(value)}
              clearable={true}
              onClear={() => setElementTiming(null)}
            />
          </div>
          <div className="mx-8 mb-8">
            {/* ICS */}
            <ContractIcs
              sheets={sheets}
              invoices={contract?.invoices ?? []}
              canWrite={canWrite}
              overrideRules={overrideRules}
              updateOverrideValues={updateOverrideValues}
              schemes={icsSchemes}
              originalOverrideValues={originalOverrideValues}
            />

            {/* UDF */}
            {canWrite && <EditUserDefinedFields
              elementId={contractId}
              type="Contract"
              setUpdatePayload={setUdfUpdatePayload}
              isSubmitted={isFormSubmitted}
              updateIsValid={setCustomFieldsAreValid}
              hasPadding={false}
              setIsLoading={setIsLoadingUserDefinedFields}
            />}

            {/* WORKFLOW */}
            <SlideOverTitle title={t('projectContract.assignedWorkflow')} />
            <FormField name="workflow">
              {(control) => (
                <ComboSelect
                  icon={<WorkflowIcon className="h-6 w-6" />}
                  options={workflowOptions}
                  // TODO after workflows can be created manually: additionalOption={<ComboSelectAdditionalOption onClick={goToWorkflowsView} label={t('app.settings.workflows.createNewWorkflowTitle')} />}
                  label={t('app.settingsWorkflow')}
                  onChange={control.onChange}
                  value={control.value}
                  disabled={!canWrite || !workflowOptions.length}
                  nullable
                />
              )}
            </FormField>
          </div>
        </SlideOver.Content>
        <SlideOver.Controls>
          <Button onClick={() => onClose(false)} className="mr-2" variant="secondary">
            {t('common.cancel')}
          </Button>
          {canWrite && (
            <Button variant="primary" formSubmit={true} innerRef={submitRef} onClick={() => setIsFormSubmitted(true)}>
              {t('common.save')}
            </Button>
          )}
        </SlideOver.Controls>
      </Form>
      {/* <Modal
        variant="custom"
        className="w-[420px] h-full"
        isOpen={isOpenAddCompanyModal}
        onClose={() => setIsOpenAddCompanyModal(false)}
      >
        <AddCompanyModal onClose={() => setIsOpenAddCompanyModal(false)} />
      </Modal> */}
    </>
  );
};
