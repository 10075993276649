import classNames from 'classnames';
import React, { ChangeEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import { createFileList, formatFileSize } from '@client/shared/utilities';
import { DocumentIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { TrashIcon } from '../icons';

interface FileInputProps {
  acceptedFileTypes: string[];
  multiple: boolean;
  className?: string;
  uploadDescription?: string | ReactNode;
  icon?: React.ReactNode;
  selectedFiles?: FileList | null;
  onChange: (fileList: FileList | null) => void;
  maxFileSize?: number;
  setError?: (error: boolean) => void;
}

export const FileInput = ({
  acceptedFileTypes,
  className,
  icon,
  multiple,
  uploadDescription,
  selectedFiles,
  onChange,
  setError,
  maxFileSize
}: FileInputProps) => {
  const { t } = useTranslation();

  const [fileList, setFileList] = React.useState<FileList | null | undefined>(selectedFiles);
  const files = fileList ? Array.from(fileList) : [];

  const [wrongFileTypeFileNames, setWrongFileTypeFileNames] = useState<string[]>([]);
  const [wrongFileSizeFileNames, setWrongFileSizeFileNames] = useState<string[]>([]);

  const wrongFileNames = useMemo(() => {
    return [...wrongFileSizeFileNames, ...wrongFileTypeFileNames];
  }, [wrongFileSizeFileNames, wrongFileTypeFileNames]);

  useEffect(() => {
    setFileList(selectedFiles);
  }, [selectedFiles]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target as HTMLInputElement;

    if (files) {
      setFileList(files);
      const list = Array.from(files);

      // File validation
      if ((acceptedFileTypes.length && !acceptedFileTypes.includes('*')) || maxFileSize) {
        const allAcceptedFileTypes = acceptedFileTypes.join('');
        const wrongTypes: string[] = [];
        const wrongSizes: string[] = [];

        list.forEach((file) => {
          const fileEnding = file.name.split('.').pop();

          // Validation of accepted file types if defined
          if (acceptedFileTypes.length && fileEnding) {
            if (!allAcceptedFileTypes.includes(fileEnding)) {
              if (
                !acceptedFileTypes.includes('image/*')
                || (acceptedFileTypes.includes('image/*') && !file.type.includes('image'))) {
                wrongTypes.push(file.name);
              }
            }
          }

          // Validation of size if maxSize defined
          if (maxFileSize && file.size >= maxFileSize) {
            wrongSizes.push(file.name);
          }
        });

        if (setError) {
          if (wrongTypes.length || wrongSizes.length) {
            setError(true);
          } else {
            setError(false);
          }
        }

        setWrongFileTypeFileNames(wrongTypes);
        setWrongFileSizeFileNames(wrongSizes);
      }
    }

    if (onChange) {
      onChange(files);
    }
  };

  const removeFile = (index: number) => {
    const files = fileList ? Array.from(fileList) : [];
    files.splice(index, 1);

    const newFileList = createFileList(...files);
    setFileList(newFileList);

    if (!newFileList.length && setError) {
      setError(false);
    }

    if (onChange) {
      onChange(newFileList);
    }
  };

  const loadPreview = (file: File) => {
    return URL.createObjectURL(file);
  };

  return (
    <div className={classNames('flex flex-col gap-2', className)}>
      <div
        className={classNames(
          'relative flex flex-col text-gray-400 rounded cursor-pointer hover:bg-gray-100 py-8 transition-colors duration-300',
          className,
        )}
      >
        <input
          className="absolute inset-0 w-full h-full p-0 m-0 outline-none opacity-0 cursor-pointer"
          multiple={multiple}
          accept={acceptedFileTypes.join(',')}
          type="file"
          onClick={(event) => {
            // allow selecting file again after removing
            event.currentTarget.value = '';
          }}
          onChange={handleChange}
          onDrop={(event) => {
            event.preventDefault();
            const files = event.dataTransfer.files;
            handleChange({ target: { files } } as ChangeEvent<HTMLInputElement>);
          }}
        />

        <div className="flex flex-col items-center justify-center text-center text-medium h-full">
          {icon ? icon : <DocumentIcon className="w-10 h-10" />}
          <p className="mt-6">{uploadDescription ? uploadDescription : t('app.fileUploadMessage')}</p>
        </div>

        {files.length > 0 && (
          <div className="absolute flex flex-row flex-wrap inset-0 overflow-x-auto p-4 gap-2">
            {files.map((file, index) => (
              <div className="relative w-24" key={`upload-file-${index}`}>
                <div
                  key={index}
                  className="flex flex-col flex-shrink-0 items-center overflow-hidden text-center bg-gray-100 border rounded select-none"
                >
                  <div
                    className="absolute top-0 right-0 z-50 p-1 bg-white rounded-bl cursor-pointer"
                    onClick={() => removeFile(index)}
                  >
                    <TrashIcon className="w-4 h-4 text-gray-700" />
                  </div>
                  <div className="flex items-center justify-center w-20 h-20">
                    {file.type.includes('image/') ? (
                      <img className="inline-block" src={loadPreview(file)} alt="Preview" />
                    ) : (
                      <DocumentIcon className="w-6 h-6 text-gray-400" />
                    )}
                  </div>
                  <div className="w-full flex flex-col p-2 text-xs bg-white bg-opacity-50">
                    <span className="w-full font-bold text-gray-900 truncate">{file.name}</span>
                    <span className="text-xs text-gray-900">{formatFileSize(file.size, 1000)}</span>
                  </div>
                </div>
                {wrongFileNames.includes(file.name) && (
                  <div className="text-xs bg-red-500 text-white p-1.5 flex flex-col items-center justify-center text-center">
                    {wrongFileTypeFileNames.includes(file.name) && <span>{t('fileUpload.error.wrongFileType')}</span>}
                    {wrongFileSizeFileNames.includes(file.name) && <span>{t('fileUpload.error.tooLarge')}</span>}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {files.length > 0 && wrongFileNames.length > 0 && (
        <div className="px-2 flex gap-1.5 text-xs text-red-500 items-center">
          <ExclamationTriangleIcon className="w-5 flex-none" />
          <div>
            {wrongFileTypeFileNames.length > 0 && (
              <div className="flex gap-1.5">
                {t('fileUpload.hint.fileType')}{' '}
                {acceptedFileTypes.join(', ')}
              </div>
            )}
            {wrongFileSizeFileNames.length > 0 && maxFileSize && (
              <div className="flex gap-1.5">
                {t('fileUpload.hint.maximumFileSize')}{' '}
                {formatFileSize(maxFileSize, 1000)}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
