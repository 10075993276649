import {
  CalculateElementRowFinanceColumn,
  Column,
  OptionalColumn,
  TimeLineView
} from './index';
import React, { useMemo } from 'react';
import {
  CommitmentDto,
  ContractDto,
  InvoicingPartyDto,
  CalculationModelEarningsElement,
  CalculationModelEarningsGroupElement,
  CalculationModelRiskElementReadModel,
  CalculationModelRiskGroupReadModel,
  CostCatalogElementDto,
  CostElementDto, FinancingElementShortReadModel,
  FinancingGroupReadModel
} from '@client/shared/api';
import { FormattedCurrency } from '@client/project/shared';
import { formatPercentage } from '@client/shared/utilities';
import { useLoadedProjectValueTypeIsNet } from '@client/project/store';

export const getOverBudget = (forecast?: number | null, budget?: number | null) => {
  if (forecast && budget && forecast > budget) {
    return ((forecast - budget) / budget)
  }
  return undefined
}

export const getUnderBudget = (forecast?: number | null, budget?: number | null) => {
  if (forecast && budget && forecast > budget) {
    return ((forecast - budget) / forecast)
  }
  return undefined
}

export type CalculateSelectableColumnProps = {
  column: OptionalColumn
  level: number
  handleOnClick?: (evt: React.MouseEvent<HTMLDivElement>) => void
  view: TimeLineView
  values?: CostCatalogElementDto  | CostElementDto | CommitmentDto
    | ContractDto | CalculationModelRiskGroupReadModel
    | CalculationModelRiskElementReadModel | CalculationModelEarningsGroupElement
    | CalculationModelEarningsElement | FinancingGroupReadModel
    | FinancingElementShortReadModel | InvoicingPartyDto
  type: 'costCatalogElement' | 'costElement' | 'contract'
    | 'commitment' | 'riskGroup' | 'riskElement'
    | 'earningsGroup' | 'earningElement'
    | 'financingGroup' | 'financingElement' | 'invoice'
  columnType: Column.OBLIGO | Column.OPTIONAL_COLUMN
}
export const CalculateSelectableColumn = (props: CalculateSelectableColumnProps) => {
  const {
    column,
    level,
    handleOnClick,
    view,
    values,
    type,
    columnType
  } = props

  const isNet = useLoadedProjectValueTypeIsNet()

  const parsedValues= useMemo(() => {
    if (!values) return undefined
    switch (type) {
      case 'costCatalogElement': {
        const val = values as CostCatalogElementDto
        return {
          forecast: val.modelValues.effectiveForecast,
          totalPayment: isNet ? val.financeTimeline?.totalPaymentNet : val.financeTimeline?.totalPaymentGross,
          obligo: val.modelValues?.obligo,
          totalContract: val.modelValues?.totalContract,
          totalSupplement: val.modelValues?.totalSupplement,
          totalInvoice: val.modelValues?.totalInvoice,
          overBudgetPercent: getOverBudget(val.modelValues?.effectiveForecast, val.modelValues?.effectiveValue)
        }
      }
      case 'costElement': {
        const val = values as CostElementDto
        return {
          forecast: val.totalForecast,
          totalPayment: val.totalPayment,
          obligo: val.obligo,
          totalContract: val.totalContract,
          totalSupplement: val.totalSupplement,
          totalInvoice: val.totalInvoice,
          overBudgetPercent: getOverBudget(val.totalForecast, val.totalValue)
        }
      }
      case 'commitment': {
        const val = values as CommitmentDto
        return {
          forecast: val.forecast,
          totalPayment: val.payment,
          obligo: val.obligo,
          totalContract: val.contract,
          totalSupplement: val.supplement,
          totalInvoice: val.invoice
        }
      }
      case 'contract': {
        const val = values as ContractDto
        return {
          forecast: val.forecast,
          totalPayment: val.payment,
          obligo: val.obligo,
          totalContract: val.contract,
          totalSupplement: val.supplement,
          totalInvoice: val.invoice
        }
      }
      case 'invoice': {
        const val = values as InvoicingPartyDto
        return {
          forecast: val.forecast,
          totalPayment: val.payment,
          obligo: val.obligo,
          totalInvoice: val.invoice
        }
      }
      case 'riskGroup': {
        const val = values as CalculationModelRiskGroupReadModel
        return {
          forecast: val.effectiveForecast
        }
      }
      case 'riskElement': {
        const val = values as CalculationModelRiskElementReadModel
        return {
          forecast: val.totalForecast
        }
      }
      case 'earningsGroup': {
        const val = values as CalculationModelEarningsGroupElement
        return {
          forecast: val.effectiveForecast,
          totalPayment: val.financeTimeline?.totalPaymentNet
        }
      }
      case 'earningElement': {
        const val = values as CalculationModelEarningsElement
        return {
          forecast: val.totalForecast,
          totalPayment: val.financeTimeline?.totalPaymentNet
        }
      }
      case 'financingGroup': {
        const val = values as FinancingGroupReadModel
        return {
          totalPayment: val.financeTimeline?.totalPaymentNet
        }
      }
      case 'financingElement': {
        const val = values as FinancingElementShortReadModel
        return {
          totalPayment: val.financeTimeline?.totalPaymentNet
        }
      }
    }
  }, [isNet, type, values])

  return (
    <CalculateElementRowFinanceColumn
      level={level}
      handleOnClick={handleOnClick}
      columnType={columnType}
      view={view}
    >
      {parsedValues && (
        <>
          {column === OptionalColumn.FORECAST && !!parsedValues.forecast && (
            <>
              {parsedValues.overBudgetPercent ? (
                <div className="flex gap-1 items-center">
                  <span className="text-[9px] text-red-800">
                    {formatPercentage(
                      parsedValues.overBudgetPercent,
                      { maxDigits: 0 }
                    )}
                  </span>
                  <FormattedCurrency amount={parsedValues.forecast} />
                </div>
              ): (
                <FormattedCurrency amount={parsedValues.forecast} />
              )}
            </>
          )}

          {column === OptionalColumn.TOTAL_PAYMENT && !!parsedValues.totalPayment && (
            <FormattedCurrency amount={parsedValues.totalPayment} />
          )}
          {column === OptionalColumn.OBLIGO && !!parsedValues.obligo && (
            <FormattedCurrency amount={parsedValues.obligo} />
          )}
          {column === OptionalColumn.CONTRACT && !!parsedValues.totalContract && (
            <FormattedCurrency amount={parsedValues.totalContract} />
          )}
          {column === OptionalColumn.SUPPLEMENT && !!parsedValues.totalSupplement && (
            <FormattedCurrency amount={parsedValues.totalSupplement} />
          )}
          {column === OptionalColumn.INVOICE && !!parsedValues.totalInvoice && (
            <FormattedCurrency amount={parsedValues.totalInvoice} />
          )}
          {column === OptionalColumn.TOTAL_CONTRACT &&
            (!!parsedValues.totalContract || !!parsedValues.totalSupplement) && (
              <FormattedCurrency amount={(parsedValues.totalContract ?? 0) + (parsedValues.totalSupplement ?? 0)} />
            )}
        </>
      )}
    </CalculateElementRowFinanceColumn>
  )
}
