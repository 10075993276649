import {parseISO} from 'date-fns/fp';
import React, { useCallback, useContext, useMemo } from 'react';
import { DatedPaymentGroupDto, FinanceTimeline } from '@client/shared/api';
import {useTranslation} from 'react-i18next';
import {
  calculateMonthOffset,
  calculateTotalMonths,
  PositionedTimeLineElementFinance,
  TimelineElementFinancePopover
} from '../index';
import {isTimelineElementInYear, TimeLineDataContext} from './TimeLineDataContext';
import { FormattedCurrency } from '@client/project/shared';
import { endOfMonth, startOfMonth } from 'date-fns';
import { useLoadedProjectValueTypeIsNet } from '@client/project/store';

interface TimeLineElementFinanceContractDetailsProps {
  startDate: Date;
  endDate: Date;
  financeTimeline: FinanceTimeline;
  paymentSum?: number
  data: TimeLineElementFinanceData
}

export const TimelineElementFinanceContractDetails = ({
  endDate,
  startDate,
  paymentSum,
  data
}: TimeLineElementFinanceContractDetailsProps) => {
  const {
    budget,
    forecast,
    payment
  } = data
  const { t } = useTranslation();
  return (
    <TimelineElementFinancePopover
      popoverButton={(
        <>
          <div className="flex flex-row justify-center items-center my-auto col-span-1 h-1/2">
            <div className="text-right font-semibold">-</div>
          </div>
          <div className="flex flex-row justify-center items-center col-span-1 h-1/2 text-xs">
            {!!paymentSum && (
              <div className="font-semibold ">
                <FormattedCurrency amount={paymentSum} /> <span className="font-normal ">IST</span>
              </div>
            )}
          </div>
        </>
      )}
    >
      <span>
        <span className="font-semibold">{t('projectCalculate.financeTimeline.startDate')}: </span>
        {startDate.toLocaleDateString()}
      </span>
      <span>
        <span className="font-semibold">{t('projectCalculate.financeTimeline.endDate')}: </span>
        {endDate.toLocaleDateString()}
      </span>
      {!!budget && budget > 0 && (
        <span>
          <span className="font-semibold">{t('projectCalculate.financeTimeline.budget')}: </span>
          <FormattedCurrency amount={budget} />
        </span>
      )}
      {!!forecast && (
        <span>
          <span className="font-semibold">{t('projectCalculate.financeTimeline.forecast')}: </span>
          <FormattedCurrency amount={forecast} />
        </span>
      )}
      {!!payment && (
        <span>
          <span className="font-semibold">{t('projectCalculate.financeTimeline.payment')}: </span>
          <FormattedCurrency amount={payment} />
        </span>
      )}
    </TimelineElementFinancePopover>
  );
};

export type TimeLineElementFinanceData = {
  id?: string
  datedPayments?: DatedPaymentGroupDto[]
  budget?: number
  forecast?: number
  payment?: number
}

interface TimeLineElementFinanceContractProps {
  timeLineStartDate?: Date;
  timeLineEndDate?: Date;
  financeTimeline: FinanceTimeline | null | undefined;
  data: TimeLineElementFinanceData
  year?: number
}

export const TimeLineElementFinanceContract = ({
 timeLineEndDate,
 timeLineStartDate,
 financeTimeline,
 data,
 year
}: TimeLineElementFinanceContractProps) => {
  const { datedPayments } = data
  const {toggledYears } = useContext(TimeLineDataContext)

  const currentYearContractPayments = useMemo(() => {
    let payments: DatedPaymentGroupDto[] = []
    if (datedPayments?.length && year) {
      payments = datedPayments.filter((payment) => {
        return isTimelineElementInYear(year, payment.date, payment.date)
      })
    }
    return payments
  }, [datedPayments, year])
  // start date is the first payment date of the year

  const startDate = useMemo(() => {
    if (financeTimeline?.start) {
      if (currentYearContractPayments.length && currentYearContractPayments[0]) {
        const startDate = currentYearContractPayments[0].date.substring(0, 10)
        return parseISO(startDate.substring(0, 10))
      }
      return parseISO(financeTimeline.start.substring(0, 10))
    }
    return undefined
  }, [currentYearContractPayments, financeTimeline?.start]);

  // end date is the last payment date of the year
  const endDate = useMemo(() => {
    if (financeTimeline?.end) {
      if (currentYearContractPayments.length && currentYearContractPayments[currentYearContractPayments.length - 1]) {
        const endDate = currentYearContractPayments[currentYearContractPayments.length - 1].date.substring(0, 10)
        return parseISO(endDate.substring(0, 10))
      }
      return parseISO(financeTimeline.end.substring(0, 10))
    }
    return undefined
  }, [financeTimeline?.end, currentYearContractPayments]);

  const isToggled = useMemo(() => {
    return year ? toggledYears.includes(year) : false
  }, [toggledYears, year])

  const isNet = useLoadedProjectValueTypeIsNet();

  const paymentSumValue = useMemo(() => {
    let paymentSum = 0
    currentYearContractPayments.forEach((elem) => {
      if (elem.paymentSumNet && elem.paymentSumGross) {
        paymentSum += isNet ? elem.paymentSumNet : elem.paymentSumGross
      }
    })
    return paymentSum
  }, [currentYearContractPayments, isNet])

  // Position left based on element's month and start month
  const getPosLeft = useCallback((element: DatedPaymentGroupDto) => {
    if (!timeLineStartDate || !timeLineEndDate || !startDate || !endDate) {
      return 0
    }
    if (!isToggled) {
      const start = startDate < timeLineStartDate ? startOfMonth(timeLineStartDate) : startOfMonth(startDate)
      const end = endDate > timeLineEndDate ? endOfMonth(timeLineEndDate) : endOfMonth(endDate)
      const totalMonthsInTimeline = calculateTotalMonths(start, end);
      const startMonthOffset = calculateMonthOffset(start, parseISO(element.date));
      const left = (startMonthOffset / totalMonthsInTimeline) * 100
      return `${left}%`
    }
    return 0
  }, [isToggled, startDate, timeLineStartDate, endDate, timeLineEndDate])

  // use memo required, because of context always updating
  return useMemo(() => {
    if (!timeLineStartDate || !timeLineEndDate || !financeTimeline || !currentYearContractPayments.length || !startDate ||!endDate) {
      return null;
    }
    return (
      <PositionedTimeLineElementFinance
        timeLineEndDate={timeLineEndDate}
        timeLineStartDate={timeLineStartDate}
        startDate={startDate}
        endDate={endDate}
        isToggled={isToggled}
      >
        {/* year is collapsed, show sum up of all values in this year */}
        {isToggled && currentYearContractPayments.length > 0 && (
          <div className="absolute top-0 left-0 h-full">
            <TimelineElementFinanceContractDetails
              {...{ startDate, endDate, timeLineStartDate, timeLineEndDate, financeTimeline }}
              data={data}
              paymentSum={paymentSumValue}
            />
          </div>
        )}
        {/* year is not collapsed, show every value */}
        {!isToggled &&
          currentYearContractPayments.map((element, idx) => (
            <div
              className="absolute top-0 h-full"
              style={{ left: getPosLeft(element) }}
              key={`financeTimelineContractChildElement-${idx}`}
            >
              <TimelineElementFinanceContractDetails
                {...{ startDate, endDate, timeLineStartDate, timeLineEndDate, financeTimeline }}
                key={`financeTimelineContractElement-${idx}`}
                data={data}
                paymentSum={isNet ? element.paymentSumNet : element.paymentSumGross}
              />
            </div>
          ))}
      </PositionedTimeLineElementFinance>
    );
  }, [timeLineStartDate, timeLineEndDate, financeTimeline, startDate, endDate, isToggled, data, currentYearContractPayments, paymentSumValue, getPosLeft, isNet])
};

