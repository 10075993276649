import {
  AddButton,
  BaseSelect,
  BaseSelectOption, DatePicker,
  LoadingIndicator,
  Modal,
  NumberInput, SlideOverTitle,
  TextInput
} from '@client/shared/toolkit';
import {
  ElementUserDefinedFieldDefinitionReadModel,
  ElementUserDefinedFieldReadModel,
  useApiGetUserDefinedFieldsDefinitionByElementQuery,
  useApiGetUserDefinedFieldsDefinitionQuery,
  useApiProjectGetUserDefinedFieldsDefinitionByElementQuery,
  useApiProjectGetUserDefinedFieldsDefinitionQuery,
  UserDefinedFieldCalculateElementType,
  UserDefinedFieldElementType, UserDefinedFieldPayload
} from '@client/shared/api';
import { checkIfFieldIsNotValid, getCustomFieldTypeIcon } from '../../utils';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectUserDefinedFieldsModal } from './SelectUserDefinedFieldsModal';
import cn from 'classnames';
import { useLoadedProjectId } from '@client/project/store';
import { formatDateOnly } from '@client/shared/utilities';

interface EditUserDefinedFieldsProps {
  selectedCustomFields?: ElementUserDefinedFieldDefinitionReadModel[] | null
  onAddClick?: () => void
  setSelectedCustomFields?: (fields: ElementUserDefinedFieldDefinitionReadModel[]) => void;
  type: UserDefinedFieldElementType
  calculateElementType?: UserDefinedFieldCalculateElementType
  elementId?: string
  setUpdatePayload?: (payload: UserDefinedFieldPayload[] | undefined) => void
  isSubmitted?: boolean
  updateIsValid?: (valid: boolean) => void
  className?: string
  showLabel?: boolean
  hasPadding?: boolean
  projectSpecific?: boolean
  projectId?: string
  marginTop?: boolean
  setIsLoading?: (isLoading: boolean) => void
}

export const EditUserDefinedFields = (props: EditUserDefinedFieldsProps) => {
  const {
    elementId,
    onAddClick,
    type,
    calculateElementType,
    setUpdatePayload,
    selectedCustomFields,
    setSelectedCustomFields,
    isSubmitted = false,
    updateIsValid,
    className,
    showLabel = true,
    hasPadding = true,
    projectSpecific = true,
    projectId,
    marginTop = true,
    setIsLoading,
  } = props

  const { t } = useTranslation()

  const loadedProjectId = useLoadedProjectId();

  const [isOpenSelectCustomFieldsModal, setIsOpenSelectCustomFieldsModal] = useState(false)

  const [fields, setFields] = useState<ElementUserDefinedFieldDefinitionReadModel[]>([])

  const { data: userDefinedFieldsResponse, isFetching: isLoadingUserDefinedFields } = useApiGetUserDefinedFieldsDefinitionByElementQuery(
    {
      elementId: elementId,
      elementType: type,
      calculateElementType: calculateElementType
    },
    { skip: !elementId || projectSpecific }
  );

  const { data: projectSpecificUserDefinedFieldsResponse, isFetching: isLoadingProjectSpecificUserDefinedFields } = useApiProjectGetUserDefinedFieldsDefinitionByElementQuery(
    {
      projectId: loadedProjectId ?? projectId ?? 'unset',
      elementId: elementId,
      elementType: type,
      calculateElementType: calculateElementType
    },
    { skip: !elementId || !projectSpecific }
  );

  const userDefinedFieldsResponseData = projectSpecific ? projectSpecificUserDefinedFieldsResponse : userDefinedFieldsResponse

  const { data: userDefinedFieldsDefinitionResponse, isFetching: isLoadingUserDefinedFieldsDefinition } = useApiGetUserDefinedFieldsDefinitionQuery(undefined, {
    skip: !!elementId || projectSpecific
  });

  const { data: projectSpecificUserDefinedFieldsDefinitionResponse, isFetching: isLoadingProjectSpecificUserDefinedFieldsDefinition } = useApiProjectGetUserDefinedFieldsDefinitionQuery(
    {
      projectId: loadedProjectId ?? projectId ?? 'unset',
    },
    { skip: !!elementId || !projectSpecific }
  );

  const userDefinedFieldsDefinitionResponseData = projectSpecific ? projectSpecificUserDefinedFieldsDefinitionResponse : userDefinedFieldsDefinitionResponse

  const checkAndSetFields = useCallback((changedFields: ElementUserDefinedFieldDefinitionReadModel[]) => {
    let someNotValid = false
    changedFields.forEach((item) => {
      const itemIsNotValid = checkIfFieldIsNotValid(item)
      if (itemIsNotValid) {
        someNotValid = true
      }
    })
    if(updateIsValid) {
      updateIsValid(!someNotValid)
    }
    // create a new update payload
    if (setUpdatePayload) {
      // create a new update payload
      const udfPayload: UserDefinedFieldPayload[] = []
      changedFields.forEach((item) => {
        if (item.userDefinedField) { // user has entered a value already
          udfPayload.push({
            userDefinedFieldDefinitionId: item.id,
            text: item.fieldType === 'Text' ? item.userDefinedField.text : null,
            number: item.fieldType === 'Number' ? item.userDefinedField.number : null,
            listSelectedItemId: item.fieldType === 'List' ? item.userDefinedField.listSelectedItem?.listItemId : null,
            date: item.fieldType === 'Date' ? item.userDefinedField.date : null,
            markedVisible: item.isVisible
          })
        } else if (item.isRequired ||
          (item.defaultText || typeof (item.defaultNumber) !== 'undefined' || item.defaultListItem?.listItemId || item.defaultDate))
        { // or it's mandatory or there is a default value
          udfPayload.push({
            userDefinedFieldDefinitionId: item.id,
            text: item.fieldType === 'Text' ? item.defaultText : null,
            number: item.fieldType === 'Number' ? item.defaultNumber : null,
            listSelectedItemId: item.fieldType === 'List' ? item.defaultListItem?.listItemId : null,
            date: item.fieldType === 'Date' ? item.defaultDate : null,
            markedVisible: item.isVisible
          })
        }
      })
      setUpdatePayload(udfPayload)
    }
    setFields(changedFields)
  }, [updateIsValid, setUpdatePayload])

  useEffect(() => {
    if (!elementId && userDefinedFieldsDefinitionResponseData && !isLoadingUserDefinedFieldsDefinition && !isLoadingProjectSpecificUserDefinedFieldsDefinition) {
      const defs = userDefinedFieldsDefinitionResponseData.userDefinedFieldsDefinition
      const filteredDefs = defs.filter((def) => {
        return def.elementType === type && def.calculateElementType === calculateElementType
      })
      checkAndSetFields(filteredDefs)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDefinedFieldsDefinitionResponseData]);

  useEffect(() => {
    let udfs: ElementUserDefinedFieldDefinitionReadModel[] = []
    if (userDefinedFieldsResponseData?.userDefinedFieldsDefinition && !isLoadingUserDefinedFields && !isLoadingProjectSpecificUserDefinedFields) {
      udfs = userDefinedFieldsResponseData.userDefinedFieldsDefinition
      if (setSelectedCustomFields) {
        setSelectedCustomFields(udfs)
      }
      checkAndSetFields(udfs)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDefinedFieldsResponseData, setSelectedCustomFields]);

  useEffect(() => {
    // selected fields were change out of this component (e.g. cost element settings modal)
    if (selectedCustomFields) {
      checkAndSetFields(selectedCustomFields)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCustomFields]);

  useEffect(() => {
    if (setIsLoading) {
      setIsLoading(isLoadingUserDefinedFields || isLoadingProjectSpecificUserDefinedFields || isLoadingUserDefinedFieldsDefinition || isLoadingProjectSpecificUserDefinedFieldsDefinition)
    }
  }, [isLoadingUserDefinedFields, isLoadingProjectSpecificUserDefinedFields, isLoadingUserDefinedFieldsDefinition, isLoadingProjectSpecificUserDefinedFieldsDefinition, setIsLoading])


  const handleOnChange = useCallback((val: string | number | string[] | Date | null, field: ElementUserDefinedFieldDefinitionReadModel) => {
    if (fields != null) {
      const foundIndex = fields.indexOf(field)
      if (foundIndex >= 0) {
        const updatedFields = [...fields]
        const fieldToUpdate = {...updatedFields[foundIndex]} as ElementUserDefinedFieldDefinitionReadModel
        let userDefinedField: ElementUserDefinedFieldReadModel | null = null

        // update udf value if it already exists
        if (fieldToUpdate.userDefinedField) {
          userDefinedField = {...fieldToUpdate.userDefinedField}
        } else { // otherwise create new one
          userDefinedField = {
            definition: fieldToUpdate,
            id: 'none',
            isMarkedVisible: false
          }
        }

        if (userDefinedField) {
          switch (field.fieldType) {
            case 'Text':
              userDefinedField.text = val?.toString() ?? null
              break;
            case 'Number':
              userDefinedField.number = typeof (val) !== 'undefined' && val !== null ? val as number : null
              break;
            case 'List': {
              const item = field.listItems?.find((item) => item.listItemId === val);
              userDefinedField.listSelectedItem = val ? { listItemId: val.toString(), label: item?.label ?? '' } : null;
              break;
            }
            case 'Date':
              userDefinedField.date = typeof (val) !== 'undefined' && val !== null ? formatDateOnly(val as Date) : null;
              break;
          }
          fieldToUpdate.userDefinedField = userDefinedField
          updatedFields[foundIndex] = fieldToUpdate

          // store the new fields
          checkAndSetFields(updatedFields)
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, setUpdatePayload])

  const getUserDefinedField = useCallback((field: ElementUserDefinedFieldDefinitionReadModel) => {
    const IconComponent = getCustomFieldTypeIcon(field.fieldType)
    const isValid = !checkIfFieldIsNotValid(field)
    switch (field.fieldType) {
      case 'Text': {
        return (
          <TextInput
            icon={<IconComponent />}
            label={field.name}
            value={field.userDefinedField?.text ?? (field.isRequired ? field.defaultText : '') ?? ''}
            onChange={(val: string) => handleOnChange(val, field)}
            isValidationValid={isValid}
            showValidation={!isValid && isSubmitted}
            helperText={!isValid && isSubmitted ? t('app.settingsUserDefinedFieldsIsRequired') : undefined}
          />
        )
      }
      case 'List': {
        const options: BaseSelectOption[] = []
        field.listItems?.forEach((listItem) => {
          options.push({
            label: listItem.label,
            value: listItem.listItemId
          })
        })
        return (
          <BaseSelect
            icon={<IconComponent />}
            label={field.name}
            value={field.userDefinedField?.listSelectedItem?.listItemId ?? (field.isRequired ? field.defaultListItem?.listItemId : '') ?? ''}
            options={options}
            onChange={(val: string) => handleOnChange(val, field)}
            isValidationValid={isValid}
            showValidation={!isValid && isSubmitted}
            helperText={!isValid && isSubmitted ? t('app.settingsUserDefinedFieldsIsRequired') : undefined}
            nullable={!field.isRequired}
          />
        );
      }
      case 'Number': {
       return (
          <NumberInput
            icon={<IconComponent />}
            label={field.name}
            value={typeof (field.userDefinedField?.number) !== 'undefined' ? field.userDefinedField?.number as number : (field.isRequired ? field.defaultNumber : null ?? null)}
            onChange={(val: number | null) => handleOnChange(val, field)}
            isValidationValid={isValid}
            showValidation={!isValid && isSubmitted}
            helperText={!isValid && isSubmitted ? t('app.settingsUserDefinedFieldsIsRequired') : undefined}
          />
        )
      }
      case 'Date': {
        return (
          <DatePicker
            label={field.name}
            value={field.userDefinedField?.date ?? (field.isRequired ? field.defaultDate : '') ?? ''}
            onChange={(val: Date | null | undefined) => handleOnChange(val ?? null, field)}
            isValidationValid={isValid}
            showValidation={!isValid && isSubmitted}
            helperText={!isValid && isSubmitted ? t('app.settingsUserDefinedFieldsIsRequired') : undefined}
          />
        )
      }
      default:
        return '';
    }
  }, [handleOnChange, isSubmitted, t])

  const visibleFields = useMemo(() => {
    return fields ? fields.filter((field) => field.isVisible) : []
  }, [fields])

  return (
    <div className={className}>
      {!setIsLoading && (isLoadingUserDefinedFields || isLoadingProjectSpecificUserDefinedFields) && (<LoadingIndicator text={t('app.settingsLoadingUserDefinedFields')} />)}
      <div className={cn({
        'px-8': hasPadding,
      },
      )}>
        {fields.length > 0 && (
          <>
            {showLabel && (
              <SlideOverTitle title={t('app.settingsUserDefinedFields')} marginTop={marginTop} />
            )}
            {visibleFields.map((field, i) => {
              return (
                <Fragment key={`edit-custom-field-${field.name}-${i}`}>
                  {getUserDefinedField(field)}
                </Fragment>
              )
            })}
          </>
        )}
      </div>
     {onAddClick && <div className={cn('-mt-4 mb-4 w-full flex justify-end relative z-10', hasPadding ? 'px-8' : '')}>
        <AddButton
            onClick={onAddClick}
            className="mr-4"
          />
        </div>
      }
      {fields && fields.length > 0 && !onAddClick && (
        <>
         <div className={cn('-mt-4 mb-4 w-full flex justify-end relative z-10', hasPadding ? 'px-8' : '')}>
          <AddButton
              onClick={() => setIsOpenSelectCustomFieldsModal(true)}
              className="mr-4"
            />
          </div>
            <Modal
              isOpen={isOpenSelectCustomFieldsModal}
              onClose={() => setIsOpenSelectCustomFieldsModal(false)}
              variant="custom"
              className="w-[360px] h-[480px]"
            >
              <SelectUserDefinedFieldsModal
                onClose={() => setIsOpenSelectCustomFieldsModal(false)}
                onChange={checkAndSetFields}
                fields={fields}
              />
            </Modal>
        </>
      )}
    </div>
  )
}
