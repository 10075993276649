import {
  Wizard,
  Button,
  Form,
  FormField,
  TextInput,
  Modal,
  FormRefHandle,
  FormWatch,
  FileInput,
  ContractNumberIcon,
  RenameIcon,
  CreateContractIcon,
  TaskCompletedDottedIcon,
  WizardSlideHeader,
  WizardSlideContent,
  LoadingIndicator,
} from '@client/shared/toolkit';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ContractFormValidationSchema, ContractFormValidationValues } from './ContractFormValidationValues';
import { useLoadedProject, useLoadedProjectId, useLoadedVariantId } from '@client/project/store';
import { safeMutation } from '@client/shared/utilities';
import {
  ApiPostCreateContractApiArg,
  ProbisErrorDataType,
  useApiGetDefaultValuesQuery,
  useApiPostGenerateNextProjectObjectCodeMutation,
  useApiPostCreateContractMutation,
} from '@client/shared/api';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useNavigate } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

interface ContractNewWizardProps {
  onClose: (id?: string) => void;
  setNewContractId?: (id?: string) => void;
}

export const ContractNewWizard = ({ onClose, setNewContractId }: ContractNewWizardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: loadedProject } = useLoadedProject();
  const loadedProjectId = useLoadedProjectId();
  const loadedVariantId = useLoadedVariantId();
  const formRef = useRef<FormRefHandle<ContractFormValidationValues>>();
  const [currentStep, setCurrentStep] = useState(0);

  const { data: defaultValues, isFetching } = useApiGetDefaultValuesQuery();
  const [getNextCode] = useApiPostGenerateNextProjectObjectCodeMutation();

  useEffect(() => {
    const getNextContractCode = async () => {
      if (loadedProjectId && loadedVariantId) {
        const isGettingNextCode = false;
        const nextCodeResponse = await safeMutation(
          getNextCode,
          {
            projectId: loadedProjectId,
            calculationModelId: loadedVariantId,
            body: { projectObjectType: 'Contract' }
          },
          isGettingNextCode,
        );
        if (typeof nextCodeResponse !== 'undefined') {
          formRef.current?.setValue('code', nextCodeResponse.code);
        }
      }
    }

    getNextContractCode();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedProjectId])


  const [postContract, { isLoading: isCreating }] = useApiPostCreateContractMutation();
  const [createdContract, setCreatedContract] = useState<string | undefined>(undefined);

  const [isCreateDisabled, setIsCreateDisabled] = useState(true);
  const [codeError, setCodeError] = useState(false);

  const vat = useMemo(() => {
    return loadedProject?.project?.payload?.vat;
  }, [loadedProject?.project?.payload?.vat]);

  const handleCloseModal = () => {
    onClose(createdContract);
  };

  const handleOnSlideChange = async (index: number) => {
    if (formRef.current) {
      await formRef.current.validateForm();
      const { isValid } = formRef.current.getState();
      if (!isValid) {
        await formRef.current.submitForm();
        await formRef.current.validateForm();
        const { isValid } = formRef.current.getState();
        if (isValid) {
          setCurrentStep(index);
        }
      }
      if (isValid) {
        setCurrentStep(index);
      }
    }
  };
  const handleSubmit = async () => {
    if (formRef.current) {
      const values = formRef.current.getValues();

      await handleCreateContract(values);
    }
  };

  const handleCreateContract = async (data: ContractFormValidationValues) => {
    setCodeError(false);
    try {
      const formData = new FormData();
      formData.append('Code', data.code);
      formData.append('Name', data.name);
      formData.append('Vat', String(vat ?? 0));
      formData.append('Discount', String(0));

      if (defaultValues?.dueDateDeadline) {
        formData.append('DueDateDeadline', String(defaultValues.dueDateDeadline));
      }
      if (defaultValues?.cashDiscountDeadline) {
        formData.append('CashDiscountDeadline', String(defaultValues.cashDiscountDeadline));
      }

      if (data.files && data.files.length > 0)
        for (let i = 0; i < data.files.length; i++) formData.append('Files', data.files[i]);

      const args = {
        projectId: loadedProjectId ?? '',
        calculationModelId: loadedVariantId ?? '',
        body: formData,
      } as ApiPostCreateContractApiArg;

      const response = await safeMutation(postContract, args, isCreating);

      if (response?.contractId) {
        setCreatedContract(response.contractId);
        if (setNewContractId) {
          setNewContractId(response.contractId);
        }
      }

      // move to last page in wizard
      setCurrentStep(currentStep + 1);
    } catch (e) {
      handleError(e);
    }
  };

  const handleError = (e: unknown) => {
    const error = e as FetchBaseQueryError;
    const data = error.data as ProbisErrorDataType;
    if (data?.code === 'error.contract.code_already_exists') {
      setCurrentStep(0);
      setCodeError(true);
    }
  };

  const defaultFormValues = {
    name: '',
    code: '',
    vat: vat,
    files: undefined,
  };

  return (
    <Form<ContractFormValidationValues>
      validationSchema={ContractFormValidationSchema}
      defaultValues={defaultFormValues}
      ref={formRef}
      className="h-full"
    >
      <Wizard
        currentStep={currentStep}
        onSlideChange={(step) => handleOnSlideChange(step)}
        className="w-[740px] h-[560px]"
      >
        {isCreating && <LoadingIndicator text={t('projectContract.wizardNewContractCreating') ?? ''} mode="overlay" />}
        {(isFetching) && <LoadingIndicator text={t('projectContract.wizardLoadingDefaultValues') ?? ''} mode="overlay" />}
        <Wizard.Slides>
          <Wizard.Slide>
            <WizardSlideHeader
              icon={<CreateContractIcon />}
              title={t('projectContract.wizardNewContract')}
              subTitle={t('projectContract.wizardNewContractSubtitle')}
              fullHeight
            />
            <WizardSlideContent className="flex">
              <FormField name="selectedType">
                {(control) => <TextInput className="hidden" disabled label="" {...control} />}
              </FormField>
              <FormWatch<ContractFormValidationValues>
                onChange={({ code, name }) => {
                  if (code && name) {
                    setIsCreateDisabled(false);
                  } else {
                    setIsCreateDisabled(true);
                  }
                }}
                fieldNames={['code', 'name']}
              >
                {() => (
                  <>
                    <FormField name="code">
                      {(control) => (
                        <TextInput
                          className="w-1/4"
                          label={t('projectContract.contractCode')}
                          icon={<ContractNumberIcon />}
                          helperTextClassName="bg-transparent"
                          helperTextTruncate={false}
                          {...control}
                          isValidationValid={!codeError && control.isValidationValid}
                          showValidation={codeError || control.showValidation}
                          helperText={codeError ? t('error.contract.code_already_exists') : control.helperText}
                        />
                      )}
                    </FormField>
                    <FormField name="name">
                      {(control) => (
                        <TextInput
                          className="flex-1 ml-1"
                          label={t('projectContract.contractName')}
                          icon={<RenameIcon />}
                          helperTextClassName="bg-transparent"
                          {...control}
                        />
                      )}
                    </FormField>
                  </>
                )}
              </FormWatch>
            </WizardSlideContent>
          </Wizard.Slide>
          <Wizard.Slide>
            <WizardSlideHeader
              title={t('projectContract.wizardUploadDocument')}
              subTitle={t('projectContract.wizardUploadDocumentDescription')}
            />
            <WizardSlideContent>
              <FormField name="files">
                {(control) => (
                  <FileInput
                    className="bg-white"
                    acceptedFileTypes={[]}
                    multiple={true}
                    {...control}
                  />
                )}
              </FormField>
            </WizardSlideContent>
          </Wizard.Slide>
          <Wizard.Slide>
            <WizardSlideHeader
              icon={<TaskCompletedDottedIcon />}
              title={t('projectContract.wizardNewContractComplete')}
              subTitle={t('projectContract.wizardNewContractCompleteDescription')}
              fullHeight
            />
          </Wizard.Slide>
        </Wizard.Slides>
        <Wizard.Navigation>
          {({ count, isFirst, isLast, canGoPrevious, canGoNext, previous, next }) => (
            <Modal.Controls
              className="bg-white"
            >
              {(() => {
                if (isFirst) {
                  return (
                    <Button variant="text" onClick={handleCloseModal}>
                      {t('common.cancel')}
                    </Button>
                  );
                } else if (isLast) {
                  return (
                    <Button variant="text" onClick={handleCloseModal}>
                      {t('common.close')}
                    </Button>
                  );
                } else {
                  return (
                    <Button variant="text" disabled={!canGoPrevious} onClick={previous}>
                      {t('common.back')}
                    </Button>
                  );
                }
              })()}
              {(() => {
                if (isLast) {
                  return (
                    <Button
                      variant="primary"
                      onClick={() => {
                        onClose();
                        if (createdContract) {
                          navigate(
                            ROUTES_CONFIG.PROJECT_CONTRACT_VIEW.path
                              .replace(':id', loadedProjectId ?? '')
                              .replace(':contractId', createdContract),
                          );
                        }
                      }}
                    >
                      {t('projectContract.createWizard.goToCreatedContractButton')}
                    </Button>
                  );
                } else if (currentStep === count - 2) {
                  return (
                    <Button variant="primary" onClick={handleSubmit} disabled={isCreateDisabled}>
                      {t('common.create')}
                    </Button>
                  );
                } else {
                  return (
                    <Button variant="primary" disabled={!canGoNext} onClick={next}>
                      {t('common.next')}
                    </Button>
                  );
                }
              })()}
            </Modal.Controls>
          )}
        </Wizard.Navigation>
      </Wizard>
    </Form>
  );
};
