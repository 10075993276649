import { HintBox, LoadingIndicator, useDocumentTitle } from '@client/shared/toolkit';
import { AppLayout, AppLayoutContent } from '../components';
import { Trans, useTranslation } from 'react-i18next';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import React, { useEffect, useMemo, useState } from 'react';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import {
  ProbisErrorDataType,
  useApiDatevTenantCallBackMutation,
  useApiDatevClientCallBackMutation,
  useApiUpdateTenantDatevClientMutation,
  useApiUpdateProjectDatevClientMutation,
} from '@client/shared/api';
import { safeMutation } from '@client/shared/utilities';
import { useDispatch } from 'react-redux';
import {
  DatevAuthState,
  removeDatevData,
  setDatevClientAuthData,
  setDatevTenantAuthData,
  updateDatevAuthData,
  useDatevAuthState,
} from '@client/project/store';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

/**
 * This route is called by Datev as redirect url.
 */
export const DatevCallbackRoute = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  useDocumentTitle({ title: t('app.menuDatevCallback') });
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [sendDatevTenantAuthData, { isLoading, isError }] = useApiDatevTenantCallBackMutation();
  const [sendDatevClientAuthData, { isLoading: isLoadingClientAuthData, isError: isLoadingClientAuthDataError }] =
    useApiDatevClientCallBackMutation();
  const [changeClient, { isLoading: isChanging }] = useApiUpdateTenantDatevClientMutation();
  const [changeProjectClient, { isLoading: isChangingProjectClient }] = useApiUpdateProjectDatevClientMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const datevAuthState = useDatevAuthState();
  const authenticatedTenantData = datevAuthState.datevTenantAuthData;
  const clientId = datevAuthState.datevClientId;
  const updateData = datevAuthState.datevUpdateData;

  useEffect(() => {
    const searchParamsDatevState = searchParams.get('state');
    const searchParamsDatevCode = searchParams.get('code');
    const searchParamsDatevSessionState = searchParams.get('session_state');
    const url = `/datev/callback?code=${searchParamsDatevCode}&session_state=${searchParamsDatevSessionState}&state=${searchParamsDatevState}`;

    const sendData = async () => {
      setErrorMessage('');
      if (updateData && clientId) {
        // UPDATE
        try {
          if (updateData.projectId) {
            await safeMutation(
              changeProjectClient,
              {
                projectId: updateData.projectId,
                body: {
                  loginResponseUrl: url,
                  selectedClientId: clientId,
                },
              },
              isChangingProjectClient,
            );
          } else {
            await safeMutation(
              changeClient,
              {
                datevApiAccessId: updateData.apiAccessId,
                body: {
                  loginResponseUrl: url,
                  selectedClientId: clientId,
                },
              },
              isChanging,
            );
          }
          dispatch(removeDatevData());
          if (updateData.projectId) {
            navigate(
              `${ROUTES_CONFIG.PROJECTS_PROJECT_SETTINGS.path.replace(
                ':id',
                updateData.projectId,
              )}?tab=connections`,
            );
          } else {
            navigate(ROUTES_CONFIG.SETTINGS_CONNECT_DATEV_EDIT.path.replace(':id', updateData.apiAccessId));
          }
        } catch (e) {
          console.error(e);
          const error = e as FetchBaseQueryError;
          const data = error.data as ProbisErrorDataType;
          if (data.message) {
            setErrorMessage(data.message);
          }
        }
      } else if (clientId) {
        // CLIENT AUTH
        try {
          const response = await safeMutation(
            sendDatevClientAuthData,
            {
              body: {
                loginResponseUrl: url,
                datevClientId: clientId,
              },
            },
            isLoadingClientAuthData,
          );
          if (response) {
            updateDatevAuthData({
              datevClientAuthData: response,
            } as DatevAuthState);
            dispatch(setDatevClientAuthData(response));
            navigate(ROUTES_CONFIG.SETTINGS_CONNECT_DATEV.path);
          }
        } catch (e) {
          console.error(e);
          const error = e as FetchBaseQueryError;
          const data = error.data as ProbisErrorDataType;
          if (data.message) {
            setErrorMessage(data.message);
          }
        }
      } else if (!authenticatedTenantData) {
        // TENANT AUTH
        try {
          const response = await safeMutation(
            sendDatevTenantAuthData,
            {
              body: {
                loginResponseUrl: url,
              },
            },
            isLoading,
          );
          if (response) {
            updateDatevAuthData({
              datevTenantAuthData: response,
            } as DatevAuthState);
            dispatch(setDatevTenantAuthData(response));
            navigate(ROUTES_CONFIG.SETTINGS_CONNECT_DATEV.path);
          }
        } catch (e) {
          console.error(e);
          dispatch(removeDatevData());
          const error = e as FetchBaseQueryError;
          const data = error.data as ProbisErrorDataType;
          if (data.message) {
            setErrorMessage(data.message);
          }
        }
      }
    };
    if (searchParamsDatevCode && searchParamsDatevSessionState) {
      sendData();
    } else if (searchParams.get('error')) {
      const errorDescription = searchParams.get('error_description');
      setErrorMessage(errorDescription ?? 'Error');
      dispatch(removeDatevData());
    } else {
      navigate(ROUTES_CONFIG.PROJECTS.path);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const linkText = useMemo(() => {
    let linkText = t('app.settingsApiDatevMarketplaceLinkText');
    if (updateData?.projectId) {
      linkText = t('app.settingsApiDatevProjectsLinkText');
    }
    return linkText;
  }, [t, updateData?.projectId]);

  const marketPlaceLink = useMemo(() => {
    let toLink = ROUTES_CONFIG.SETTINGS_CONNECT_DATEV.path;
    if (updateData && clientId) {
      toLink = ROUTES_CONFIG.SETTINGS_CONNECT_DATEV_EDIT.path.replace(':id', updateData.apiAccessId);
      if (updateData.projectId) {
        toLink = `${ROUTES_CONFIG.PROJECTS_PROJECT_SETTINGS.path.replace(
          ':id',
          updateData.projectId,
        )}?tab=datevConnections`;
      }
    }
    return (
      <NavLink className="font-bold underline" to={toLink}>
        {linkText}
      </NavLink>
    );
  }, [updateData, clientId, linkText]);

  return (
    <AppLayout>
      <AppLayoutContent>
        {!isError && !isLoadingClientAuthDataError && !errorMessage ? (
          <LoadingIndicator mode="overlay-window" text={t('app.settingsApiDatevWaitForRedirect')} />
        ) : (
          <HintBox hintType="warning">
            <Trans
              i18nKey="app.settingsApiDatevAuthError"
              values={{ linkText: linkText }}
            >
              An error occurred while connecting to Datev. Please go to {marketPlaceLink} and try to to establish a
              connection again.
            </Trans>
            <div className="font-bold mt-2">{errorMessage}</div>
          </HintBox>
        )}
      </AppLayoutContent>
    </AppLayout>
  );
};
