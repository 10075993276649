import { formatDateOnly, safeMutation } from '@client/shared/utilities';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DatevMappingAddSlideOver, DatevMappingDeleteModal, DatevMappingHeaderMenu } from '@client/project/shared';
import {
  AddButton,
  Button,
  ContextMenu,
  ContextMenuItem,
  EditListIcon,
  FilledLockIcon,
  HintBox,
  LoadingIndicator,
  Modal,
  PencilIcon,
  SlideOverSortableList,
  SlideOverSortableListItem,
  SortDownIcon,
  SortingArrowsIcon,
  TrashIcon,
} from '@client/shared/toolkit';
import classNames from 'classnames';
import {
  DatevProbisMappingReadModel,
  useApiChangeProjectDatevClientMutation,
  useApiCreateDatevAuthenticationUrlQuery,
  useApiGetDatevActiveClientsQuery,
  useApiGetExternalApisQuery,
  useApiGetProjectDatevConnectionQuery,
  useApiUpdateDatevAccountingMonthMutation,
} from '@client/shared/api';
import { DatevAuthState, updateDatevAuthData, useLoadedProjectId } from '@client/project/store';
import { useNavigate } from 'react-router-dom';
import { ROUTES_CONFIG } from '@client/shared/permissions';

interface EditProjectConnectionsProps {
  projectId: string;
  setIsDatevImportEnabled: (enabled: boolean) => void;
  activeDatevClient: boolean;
}

export const EditProjectConnections = ({
  projectId,
  setIsDatevImportEnabled,
  activeDatevClient,
}: EditProjectConnectionsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loadedProjectId = useLoadedProjectId();

  const { data: datevData, isFetching: isFetchingDatev } = useApiGetProjectDatevConnectionQuery({
    projectId: projectId,
  });

  const [updateActiveClient, { isLoading: isUpdatingDatevClient }] = useApiChangeProjectDatevClientMutation();
  const [updateAccountingMonth, { isLoading: isUpdatingAccountingMonth }] = useApiUpdateDatevAccountingMonthMutation();

  const { data: projectClients, isFetching: isFetchingClients } = useApiGetDatevActiveClientsQuery(undefined);

  useEffect(() => {
    setIsDatevImportEnabled(
      isFetchingDatev ? false : datevData?.datevProbisMapping ? datevData.datevProbisMapping.length > 0 : false,
    );
  }, [datevData?.datevProbisMapping, isFetchingDatev, setIsDatevImportEnabled]);

  const [selectedClient, setSelectedClient] = useState<{ clientId: string; datevClientId: string }>({
    clientId: datevData?.clientDetail?.id ?? '',
    datevClientId: '',
  });
  const [isOpenDatevEditSlideover, setIsOpenDatevEditSlideover] = useState(false);
  const [isDeleteDatevModalOpen, setIsDeleteDatevModalOpen] = useState(false);
  const [selectedDatevItem, setSelectedDatevItem] = useState<DatevProbisMappingReadModel | null>(null);
  const [selectedYear, setSelectedYear] = useState<string>('');
  const [selectedMonth, setSelectedMonth] = useState<string>('');

  const currentClient = useMemo(() => {
    return datevData?.clientDetail?.id;
  }, [datevData?.clientDetail?.id]);

  const selectedClientHasAccess = useMemo(() => {
    return projectClients?.clients.find((client) => client.id === selectedClient.clientId)?.hasAccessAvailable;
  }, [projectClients?.clients, selectedClient.clientId]);

  const { data: externalApis, isFetching } = useApiGetExternalApisQuery();

  const { data: createDatevAuthUrlResponse, isFetching: isFetchingDatevAuthUrl } =
    useApiCreateDatevAuthenticationUrlQuery(
      {
        datevClientId: selectedClient.datevClientId,
      },
      {
        skip:
          !selectedClient.datevClientId || selectedClient.datevClientId === currentClient || selectedClientHasAccess,
        refetchOnMountOrArgChange: true,
      },
    );

  const yearOptions = useMemo(() => {
    const yearSet = new Set<string>();
    datevData?.clientDetail?.accountingInformation.forEach(({ fiscalYearStart }) => {
      const year = String(new Date(fiscalYearStart).getFullYear());
      yearSet.add(year < '2000' ? '2000' : year);
    });
    return Array.from(yearSet).map((year) => ({ value: year, label: year }));
  }, [datevData?.clientDetail?.accountingInformation]);

  const monthOptions = useMemo(() => {
    const year = datevData?.clientDetail?.accountingInformation.find(
      (accountingInformation) => new Date(accountingInformation.fiscalYearStart).getFullYear() === Number(selectedYear),
    );
    const fiscalYearStart = year ? new Date(year.fiscalYearStart).getMonth() : new Date().getMonth();
    const fiscalYearEnd = year ? new Date(year.fiscalYearEnd).getMonth() : new Date().getMonth();

    return [
      { value: '0', label: t('common.monthJanuary'), disabled: fiscalYearStart > 0 && fiscalYearEnd < 0 },
      { value: '1', label: t('common.monthFebruary'), disabled: fiscalYearStart > 1 && fiscalYearEnd < 1 },
      { value: '2', label: t('common.monthMarch'), disabled: fiscalYearStart > 2 && fiscalYearEnd < 2 },
      { value: '3', label: t('common.monthApril'), disabled: fiscalYearStart > 3 && fiscalYearEnd < 3 },
      { value: '4', label: t('common.monthMay'), disabled: fiscalYearStart > 4 && fiscalYearEnd < 4 },
      { value: '5', label: t('common.monthJune'), disabled: fiscalYearStart > 5 && fiscalYearEnd < 5 },
      { value: '6', label: t('common.monthJuly'), disabled: fiscalYearStart > 6 && fiscalYearEnd < 6 },
      { value: '7', label: t('common.monthAugust'), disabled: fiscalYearStart > 7 && fiscalYearEnd < 7 },
      { value: '8', label: t('common.monthSeptember'), disabled: fiscalYearStart > 8 && fiscalYearEnd < 8 },
      { value: '9', label: t('common.monthOctober'), disabled: fiscalYearStart > 9 && fiscalYearEnd < 9 },
      { value: '10', label: t('common.monthNovember'), disabled: fiscalYearStart > 10 && fiscalYearEnd < 10 },
      { value: '11', label: t('common.monthDecember'), disabled: fiscalYearStart > 11 && fiscalYearEnd < 11 },
    ];
  }, [datevData?.clientDetail?.accountingInformation, t, selectedYear]);

  useEffect(() => {
    setSelectedYear(String(datevData?.accountingMonth ? new Date(datevData?.accountingMonth).getFullYear() : ''));
  }, [datevData?.accountingMonth, yearOptions]);

  useEffect(() => {
    setSelectedMonth(String(datevData?.accountingMonth ? new Date(datevData?.accountingMonth).getMonth() : ''));
  }, [datevData?.accountingMonth, monthOptions]);

  const handleAdd = () => {
    setSelectedDatevItem(null);
    setIsOpenDatevEditSlideover(true);
  };

  const handleEdit = (item: DatevProbisMappingReadModel) => {
    setSelectedDatevItem(item);
    setIsOpenDatevEditSlideover(true);
  };

  const connections = useMemo(
    () => [
      {
        id: 'datev',
        name: 'Datev',
        icon: <img src="/assets/datev-logo.svg" alt="Datev Logo" className="w-7 h-7" />,
        items: datevData?.datevProbisMapping
          .reduce(
            (acc: { name: string; items: typeof datevData.datevProbisMapping }[], item) => {
              const group = acc.find((group) => group.name === item.type);

              if (!group) {
                acc.push({
                  name: item.type,
                  items: [item],
                });
              } else {
                group.items.push(item);
              }

              return acc;
            },
            [] as { name: string; items: typeof datevData.datevProbisMapping }[],
          )
          .sort((a, b) => a.name.localeCompare(b.name)),
      },
    ],
    [datevData],
  );

  // const defaultSortHeader: SortHeaderType[] = useMemo(() => {
  //   return [
  //     {
  //       asc: true,
  //       label: t('app.settingsApiDatevSynchronised'),
  //       type: 'string',
  //       value: 'synchronised',
  //     },
  //     {
  //       asc: null,
  //       label: t('app.settingsApiDatevFailed'),
  //       type: 'string',
  //       value: 'failed',
  //     },
  //     {
  //       asc: null,
  //       label: t('app.settingsApiDatevExcluded'),
  //       type: 'string',
  //       value: 'excluded',
  //     },
  //   ];
  // }, [t]);

  // const [sortHeader, setSortHeader] = useState<SortHeaderType[]>(defaultSortHeader);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  useEffect(() => {
    if (!!selectedClient.clientId && selectedClient.clientId !== currentClient && selectedClientHasAccess) {
      try {
        safeMutation(
          updateActiveClient,
          {
            datevClientId: selectedClient.clientId,
            projectId: projectId,
          },
          isUpdatingDatevClient,
        );
      } catch (e) {
        console.error(e);
      }
    } else if (
      !!selectedClient.clientId &&
      selectedClient.clientId !== currentClient &&
      createDatevAuthUrlResponse?.url
    ) {
      updateDatevAuthData({
        datevClientId: selectedClient.clientId,
        datevUpdateData: {
          projectId: projectId,
          apiAccessId:
            externalApis?.find((api) => api.api.name === 'Datev')?.accessItems.find((accessItem) => accessItem.isActive)
              ?.id ?? '',
        },
      } as DatevAuthState);
      window.open(createDatevAuthUrlResponse?.url, '_self');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient, createDatevAuthUrlResponse]);

  const handleChangeAccountingMonth = async (month: string, year: string) => {
    if (projectId) {
      try {
        await safeMutation(
          updateAccountingMonth,
          {
            projectId: projectId,
            body: {
              accountingMonth: formatDateOnly(new Date(Number(year ?? 2000), Number(month ?? 0), 1)),
            },
          },
          isUpdatingAccountingMonth,
        );
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (selectedClient.clientId !== currentClient)
      setSelectedClient({
        clientId: datevData?.clientDetail?.id ?? '',
        datevClientId: '',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datevData?.clientDetail?.id]);

  const clientOptions = useMemo(() => {
    return (
      projectClients?.clients.map((client) => {
        return {
          label: client.name,
          value: client.id,
        };
      }) ?? []
    );
  }, [projectClients]);

  const listItems = useMemo(() => {
    return connections.map((connection) => {
      return connection.items?.map((item, i) => {
        // const contextMenuItems: ContextMenuItem[] = [];

        return {
          id: item.name,
          name: item.name,
          description: '',
          children: expandedItems.includes(item.name) && (
            <ul className="w-full">
              {datevData?.datevProbisMapping
                ?.filter((mapping) => mapping.type === item.name)
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item, i) => {
                  const mappingContextMenuItems: ContextMenuItem[] = [
                    {
                      label: t('common.edit'),
                      subtitle: t('app.settingsApiDatevConnectionEdit'),
                      icon: <PencilIcon />,
                      onClick: () => {
                        handleEdit(item);
                      },
                    },
                    {
                      label: t('common.delete'),
                      subtitle: t('app.settingsApiDatevConnectionDelete'),
                      icon: <TrashIcon />,
                      onClick: () => {
                        setSelectedDatevItem(item);
                        setIsDeleteDatevModalOpen(true);
                      },
                      stopPropagation: true,
                      isDisabled: item.isMandatoryElement,
                    },
                  ];
                  const listItem = {
                    id: item.id,
                    name: (
                      <div className="flex items-center gap-2 h-full">
                        {item.name}
                        {item.isMandatoryElement && (
                          <span className="h-full">
                            <FilledLockIcon className="h-2.5" />
                          </span>
                        )}
                      </div>
                    ),
                    description: item.description,
                    icon: <SortingArrowsIcon className="rotate-90 h-6" />,
                    cols: [
                      {
                        value: item.datevPropertyName,
                        title: t('app.settingsApiDatevConnectionDatevId'),
                      },
                      {
                        value: item.probisPropertyName ?? '',
                        title: t('app.settingsApiDatevConnectionProbisObjectId'),
                      },
                      {
                        value: item.userDefinedFieldName ?? '',
                        title: t('app.settingsApiDatevConnectionCustomField'),
                      },
                      {
                        value: item.defaultValue ?? '',
                        title: t('app.settingsApiDatevConnectionDefaultValue'),
                      },
                    ],
                    contextMenu: <ContextMenu items={mappingContextMenuItems} stopPropagation />,
                    className: 'bg-white',
                  };

                  return (
                    <li
                      className={classNames('w-full')}
                      key={`slide-over-list-item-${item.id}-${i}`}
                      onClick={() => handleEdit(item)}
                    >
                      <SlideOverSortableListItem
                        item={listItem}
                        gridCols="grid-cols-4"
                        level={2}
                        earlyCompact={false}
                      />
                    </li>
                  );
                })}
            </ul>
          ),
          icon: (
            <span className="flex h-full w-14 gap-2 ">
              <SortDownIcon
                className={classNames('h-5', !expandedItems.includes(item.name) && '-rotate-90')}
                onClick={(e) => {
                  e.stopPropagation();

                  expandedItems.includes(item.name)
                    ? setExpandedItems(expandedItems.filter((name) => name !== item.name))
                    : setExpandedItems([...expandedItems, item.name]);
                }}
              />
              <EditListIcon className="h-5" />
            </span>
          ),
          // cols: [
          //   {
          //     value: '0',
          //     header: t('app.settingsApiDatevSynchronised'),
          //   },
          //   {
          //     value: '0',
          //     header: t('app.settingsApiDatevFailed'),
          //   },
          //   {
          //     value: '0',
          //     header: t('app.settingsApiDatevExcluded'),
          //   },
          // ],
          // contextMenu: <ContextMenu items={contextMenuItems} stopPropagation />,
          className: 'bg-white',
        };
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connections, datevData, expandedItems, t, clientOptions]);

  return (
    <div>
      {(isFetchingDatev ||
        isFetchingClients ||
        isUpdatingDatevClient ||
        isUpdatingAccountingMonth ||
        isFetching ||
        isFetchingDatevAuthUrl) && (
        <LoadingIndicator text={t('app.settingsApiDatevLoadingClient') ?? ''} mode="overlay" />
      )}
      <ul className="flex flex-col gap-5">
        {!isFetchingClients && projectClients?.clients.length === 0 && (
          <HintBox hintType="info">
            <div className="h-full flex flex-col justify-center items-center">
              <div className="p-2 mb-2 text-center">{t('app.settingsApiDatevNoClientConfigured')}</div>
              <Button
                variant="primary"
                onClick={() =>
                  navigate({
                    pathname: ROUTES_CONFIG.SETTINGS_CONNECT_DATEV.path.replace(':id', loadedProjectId ?? ''),
                  })
                }
              >
                {t('app.settingsConnectMarketplace')}
              </Button>
            </div>
          </HintBox>
        )}
        {connections.map((connection, i) => (
          <li key={connection.id} className="relative">
            <SlideOverSortableList
              data={listItems[i] ?? []}
              handleSelect={(i: number) => undefined}
              sortHeader={[]}
              // onHandleSort={onHandleSort}
              headline={
                i === 0 ? (
                  <span className="text-[13px] text-gray-400">{t('app.settingsApiDatevSynchronisation')}</span>
                ) : undefined
              }
              bgColor=" "
              noItemsMessage={t('app.settingsApiDatevNoConnections')}
              gridCols="grid-cols-3"
              contextMenu
              additionalTopChildren={
                <div className="w-full h-[60px] bg-gray-200 flex justify-between items-center">
                  <div className="flex items-center justify-start w-8/12">
                    <div className="w-20 flex items-center justify-center">{connection.icon}</div>
                    <span className="text-lg font-bold mr-2">{connection.name}</span>
                  </div>
                  {connection.name === 'Datev' && activeDatevClient && (
                    <div className="flex">
                      {selectedClient.clientId && (
                        <div className="flex">
                          <DatevMappingHeaderMenu
                            className="w-60 mr-5"
                            label={t('app.settingsApiDatevDefaultYear')}
                            options={yearOptions}
                            value={selectedYear}
                            onChange={(value) => {
                              setSelectedYear(value);
                              if (value !== '') {
                                handleChangeAccountingMonth(selectedMonth, value);
                              }
                            }}
                          />
                          <DatevMappingHeaderMenu
                            className="w-60 mr-5"
                            label={t('app.settingsApiDatevDefaultMonth')}
                            options={monthOptions}
                            value={selectedMonth}
                            onChange={(value) => {
                              setSelectedMonth(value);
                              if (value !== '') {
                                handleChangeAccountingMonth(value, selectedYear);
                              }
                            }}
                          />
                          <DatevMappingHeaderMenu
                            className="w-60 mr-5"
                            label={t('app.settingsApiDatevConnectedClient')}
                            options={clientOptions.sort((a, b) => a.label.localeCompare(b.label))}
                            value={selectedClient.clientId}
                            onChange={(value) => {
                              setSelectedClient({
                                clientId: value,
                                datevClientId:
                                  projectClients?.clients.find((client) => client.id === value)?.clientId ?? '',
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              }
            />
            {connection.name === 'Datev' && selectedClient.clientId !== '' && (
              <div className="absolute right-10 -bottom-5">
                <AddButton onClick={handleAdd} />
              </div>
            )}
          </li>
        ))}
      </ul>
      <DatevMappingAddSlideOver
        isOpen={isOpenDatevEditSlideover}
        onClose={() => setIsOpenDatevEditSlideover(false)}
        item={selectedDatevItem}
        projectId={projectId}
        connectionData={datevData}
      />
      <Modal isOpen={isDeleteDatevModalOpen} onClose={setIsDeleteDatevModalOpen}>
        <DatevMappingDeleteModal onClose={setIsDeleteDatevModalOpen} item={selectedDatevItem} />
      </Modal>
    </div>
  );
};
